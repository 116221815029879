<template>
  <v-form @submit="submitEditOrCreate">
    <ion-row v-if="false">
      <ion-col size="auto">
        <ion-thumbnail>
          <img
            src="assets/icon/plagas/icoPlgMaleza.png"
            v-if="tipoPlaga === 'Maleza'"
          />
          <img
            src="assets/icon/plagas/icoPlgInsecto.png"
            v-if="tipoPlaga === 'Insecto'"
          />
          <img
            src="assets/icon/plagas/icoPlgEnferm.png"
            v-if="tipoPlaga === 'Enfermedad'"
          />
        </ion-thumbnail>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col style="margin-left: 4px">
        <ion-label>
          Producto Aplicado
        </ion-label>
      </ion-col>
    </ion-row>
    <!--     <ion-item>
      <ion-label position="floating">Producto</ion-label>
      <ion-select
        :value="producto"
        @ionChange="producto = $event.target.value"
        :placeholder="producto && producto.nombre ? producto.nombre : undefined"
      >
        <ion-select-option v-for="p in productos" :value="p" :key="p._id">
          {{ p.nombre + " (" + p.principioActivo + ")" }}
        </ion-select-option>
      </ion-select>
    </ion-item> -->
    <ion-searchbar
      placeholder="Nombre o Principio Activo"
      @ionChange="filtro = $event.target.value"
    />
    <ion-item v-if="filtro === ''">
      <ion-label style="font-weight: 600">
        {{ producto ? producto.nombre : "" }}
      </ion-label>
    </ion-item>
    <ion-list lines="full" v-for="prod in productos" :key="prod._id">
      <ion-item
        class="itemBorder"
        button="true"
        type="button"
        @click="selectProducto(prod)"
      >
        <ion-label style="white-space: normal; font-size: 12px !important">
          {{ prod.nombre + " (" + prod.principioActivo + ")" }}
        </ion-label>
      </ion-item>
    </ion-list>
    <ion-row
      :class="
        filtro
          ? 'ion-margin-top ion-align-items-center'
          : 'ion-align-items-center'
      "
    >
      <ion-col size="8">
        <ion-item>
          <ion-label position="floating">Dosis</ion-label>
          <ion-input
            v-model="dosis"
            autocapitalize="off"
            type="text"
            inputmode="text"
            autocomplete="off"
          ></ion-input>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-label position="floating" v-if="showUnidadLabel">
          Unidad
        </ion-label>
        <ion-select
          :value="unidad"
          @ionChange="unidad = $event.target.value"
          placeholder="Unidad"
        >
          <ion-select-option v-for="u in unidades" :value="u" :key="u">
            {{ u }}
          </ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>

    <ion-button
      fill="clear"
      size="large"
      color="danger"
      @click="$emit('delete')"
      v-if="editing"
    >
      <ion-icon :md="icons.trash" :ios="icons.trash"></ion-icon>
    </ion-button>
    <ion-row class="ion-float-right">
      <ion-col size="auto">
        <ion-button
          fill="outline"
          class="ion-margin-top ion-float-end"
          color="medium"
          @click="cancel"
        >
          Cancelar
        </ion-button>
      </ion-col>
      <ion-col size="auto">
        <ion-button
          class="ion-float-right ion-margin-top ion-margin-bottom"
          @click="submitEditOrCreate"
        >
          Aceptar
        </ion-button>
      </ion-col>
    </ion-row>
  </v-form>
</template>

<script>
import * as V from "vee-validate/dist/vee-validate";
import {
  IonInput,
  alertController,
  IonLabel,
  IonItem,
  IonSearchbar,
  IonList,
  IonSelect,
  IonSelectOption,
  //IonCardHeader,
  IonRow,
  IonCol,
  IonThumbnail,
  IonIcon,
  //IonCardTitle,
  //IonRow,
  //IonGrid,
  IonButton
} from "@ionic/vue";
import { mapState } from "vuex";
import { trashOutline, addOutline } from "ionicons/icons";
import { ProductosPlaga } from "@/api";
//import http from "@/api/http";

export default {
  components: {
    IonInput,
    //IonCardHeader,
    IonSelect,
    IonSelectOption,
    IonSearchbar,
    IonThumbnail,
    IonLabel,
    IonItem,
    IonList,
    //IonCardTitle,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    VForm: V.Form
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    productoPlaga: { type: Object, default: () => ({}) }
  },

  data: () => ({
    reset: false,
    dosis: "",
    unidad: "",
    unidades: ["litros/ha", "cm3/ha", "gr/ha", "kg/ha"],
    producto: null,
    productos: [],
    filtro: "",
    delayTimer: null,
    icons: {
      trash: trashOutline,
      add: addOutline
    }
  }),

  computed: {
    ...mapState(["user"]),
    editing() {
      return this.productoPlaga._id;
    },
    showUnidadLabel() {
      return this.unidad && this.unidad !== "";
    }
  },

  created() {
    if (this.editing) {
      this.fillFields();
    }
  },

  async mounted() {
    // console.log(this.productoPlaga);
    setTimeout(() => this.dataSync(),1500);
  },

  watch: {
    success(val) {
      if (val) {
        this.cleaner();
      }
    },
    async filtro(val) {
      if (val !== "") {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
          this.productos = await this.getProductos();
        }, 500);
      } else {
        this.productos = [];
      }
    }
  },

  methods: {
    cleaner() {
      this.producto = null;
      this.dosis = "";
      this.unidad = "";
    },

    selectProducto(prod) {
      this.producto = prod;
      this.filtro = "";
    },

    dataSync() {
      if (this.editing) {
        this.fillFields();
      }
    },

    async getProductos() {
      const query = {
        deleted: false,
        //tipoPlaga: this.tipoPlaga,
        $or: [
          { nombreLower: { $regex: this.filtro.toLowerCase(), $options: "i" } },
          { principioActivo: { $regex: this.filtro, $options: "i" } }
        ]
      };
      const { data, error } = await ProductosPlaga.get({
        query: query,
        sort: { nombreLower: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Productos.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    async submitEditOrCreate() {
      let error2 = "";
      if (!this.producto) {
        error2 += "<li>Elija un <b>Producto</b>." + `<br/></li>`;
      }
      if (!this.unidad) {
        error2 += "<li>Elija una <b>Unidad</b>." + `<br/></li>`;
      }
      if (!this.dosis) {
        error2 += "<li>Complete el campo <b>Dosis</b>." + `<br/></li>`;
      } else if (isNaN(this.dosis)) {
        error2 += "<li>El campo <b>Dosis</b> es numérico." + `<br/></li>`;
      } else if (this.dosis < 0) {
        error2 +=
          "<li>El campo <b>Dosis</b> debe ser un número positivo." +
          `<br/></li>`;
      }
      if (!error2) {
        this.$emit("submit", {
          productoPlaga: {
            _id: this.productoPlaga._id,
            producto: this.producto,
            dosis: this.dosis,
            unidad: this.unidad
          }
          //editing: this.editing
        });
      } else {
        const alert = await alertController.create({
          header: "Error al agregar el Producto",
          message: error2,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    fillFields() {
      this.producto = this.productoPlaga.producto;
      this.dosis = this.productoPlaga.dosis;
      this.unidad = this.productoPlaga.unidad;
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

.smallFont {
  font-size: small;
}

ion-item {
  --pading-start: 8px;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

ion-select {
  --placeholder-opacity: 1 !important;
  --placeholder-color: var(--ion-color-dark);
}

ion-content {
  --background: transparent;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}
</style>
