<template>
  <v-form @submit="submitEditOrCreate">
    <ion-item id="campoAngosto">
      <ion-label position="floating">
        Fecha
      </ion-label>
      <ion-datetime v-model="fecha" :value="fecha" display-timezone="Etc/GMT+3" display-format="DD/MM/YY"
        picker-format="DD/MM/YY" cancelText="Cancelar" doneText="Aceptar"></ion-datetime>
    </ion-item>
    <ion-row id="campoAngosto">
      <ion-col>
        <ion-item>
          <ion-label position="floating">Densidad</ion-label>
          <ion-input v-model="densidad" autocapitalize="off" type="text" inputmode="text" autocomplete="off" required
            placeholder="Semillas"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col class="ion-align-self-center ion-margin-top" size="auto">
        <ion-select :value="unidad" @ionChange="unidad = $event.target.value" placeholder="Unidad">
          <ion-select-option v-for="u in ['semillas/m2', 'semillas/ha']" :value="u" :key="u">
            {{ u }}
          </ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
    <ion-row id="campoAngosto" style="margin-bottom: 8px">
      <ion-col>
        <ion-item>
          <ion-label position="floating">Espaciamiento</ion-label>
          <ion-input v-model="espaciamiento" autocapitalize="off" type="text" inputmode="text" autocomplete="off" required
            placeholder="cm"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col class="ion-align-self-center ion-margin-top" size="auto">
        <h2>
          cm
        </h2>
      </ion-col>
    </ion-row>
    <ion-label style="margin-left: 8px">
      Hace Variable de Semilla?
    </ion-label>
    <ion-radio-group v-model="variableSemilla">
      <ion-grid fixed>
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-label style="margin-right: 8px">Si</ion-label>
            <ion-radio @click="variableSemilla = 'Si'" value="Si"></ion-radio>
          </ion-col>
          <ion-col size="auto">
            <ion-label style="margin-right: 8px">No</ion-label>
            <ion-radio @click="variableSemilla = 'No'" value="No"></ion-radio>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-radio-group>
    <div class="ion-margin-top" v-if="!editing">
      <ion-label style="margin-left: 8px">Realiza Fertlización?</ion-label>
      <ion-radio-group v-model="realizaFertilizacion">
        <ion-grid fixed>
          <ion-row class="ion-align-items-center">
            <ion-col size="auto">
              <ion-label style="margin-right: 8px">Si</ion-label>
              <ion-radio @click="realizaFertilizacion = 'Si'" value="Si"></ion-radio>
            </ion-col>
            <ion-col size="auto">
              <ion-label style="margin-right: 8px">No</ion-label>
              <ion-radio @click="realizaFertilizacion = 'No'" value="No"></ion-radio>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-radio-group>
    </div>

    <ion-button fill="clear" size="large" color="danger" @click="$emit('delete')" v-if="editing">
      <ion-icon :md="icons.trash" :ios="icons.trash"></ion-icon>
    </ion-button>
    <ion-row class="ion-float-right">
      <ion-col size="auto">
        <ion-button fill="outline" class="ion-margin-top ion-float-end" color="medium" @click="cancel">
          Cancelar
        </ion-button>
      </ion-col>
      <ion-col size="auto">
        <ion-button class="ion-float-right ion-margin-top ion-margin-bottom" @click="submitEditOrCreate">
          Aceptar
        </ion-button>
      </ion-col>
    </ion-row>
  </v-form>

  <ion-modal :is-open="modalLotes" @didDismiss="modalLotes = false" :backdropDismiss="false">
    <ion-content :scroll-events="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title>Otros lotes a aplicar Actividad</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list v-for="l in lotes" :value="l" :key="l._id">
            <ion-item button="false" class="itemBorder">
              <ion-checkbox @ionChange="agregarLote(l._id)"></ion-checkbox>
              <ion-label class="ion-text-capitalize" style="margin-left: 8px; white-space: normal;">
                {{ l.establecimiento + " - " + l.lote + " (" + l.cultivo + " - " + l.variedad + ")" }}
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-row class="ion-float-right">
            <ion-col size="auto">
              <ion-button fill="outline" class="ion-margin-top ion-float-end" color="medium" @click="cancelLotesModal">
                Cancelar
              </ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button class="ion-float-right ion-margin-top ion-margin-bottom" @click="aceptLotesModal">
                Aceptar
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-modal>
</template>

<script>
import * as V from "vee-validate/dist/vee-validate";
import {
  IonDatetime,
  IonInput,
  IonModal,
  IonLabel,
  IonCheckbox,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonSelect,
  IonSelectOption,
  alertController,
  //IonText,
  //IonCardHeader,
  IonItem,
  /* IonImg,
  IonThumbnail, */
  IonIcon,
  //IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonRadio,
  //IonGrid,
  IonButton
} from "@ionic/vue";
import { mapState, mapActions } from "vuex";
import { trashOutline } from "ionicons/icons";
import { Lotes, CampaniaCultivos } from "@/api";

export default {
  components: {
    IonDatetime,
    IonInput,
    //IonText,
    //IonCardHeader,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonModal,
    IonItem,
    //IonCardTitle,
    //IonRow,
    /* IonImg,
    IonThumbnail,
    IonGrid, */
    IonGrid,
    IonCheckbox,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonRow,
    IonCol,
    IonRadioGroup,
    IonRadio,
    IonButton,
    IonIcon,
    VForm: V.Form
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    campaniaCultivo: { type: Object, default: () => ({}) },
    actividad: { type: Object, default: () => ({}) },
    personaActual: { type: Object, default: () => ({}) }
  },

  data: () => ({
    fecha: "",
    densidad: "",
    espaciamiento: "",
    variableSemilla: "",
    realizaFertilizacion: "",
    unidad: "semillas/m2",
    modalLotes: false,
    lotes: [],
    lotesElegidos: [],
    confirmarSubmit: false,
    deleteAct: false,
    icons: {
      trash: trashOutline
    }
    //hora: ""
    //error: "",
  }),

  computed: {
    ...mapState(["user"]),
    editing() {
      return this.actividad._id;
    }
  },

  async mounted() {
    await this.getMe();
    this.dataSync();
  },

  watch: {
    success(val) {
      if (val) {
        this.cleaner();
      }
    },
    actividad() {
      this.cleaner();
      this.dataSync();
    },
    // unidad(val) {
    //   console.log(val);
    // }
  },

  methods: {
    ...mapActions(["getMe"]),
    cleaner() {
      this.fecha = new Date().toISOString();
      this.densidad = "";
      this.espaciamiento = "";
      this.realizaFertilizacion = "";
      this.variableSemilla = "";
      //this.error = "";
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    dataSync() {
      if (this.editing) {
        this.fecha = new Date(this.actividad.fecha).toISOString();
        this.densidad = this.actividad.densidad || "";
        this.espaciamiento = this.actividad.espaciamiento || "";
        this.variableSemilla = this.actividad.variableSemilla || "";
      } else {
        setTimeout(async () => {
          this.lotes = await this.getCampaniasCultivoPropias();
        }, 1500);
      }
    },

    agregarLote(id) {
      this.lotesElegidos.push(id);
    },

    async openLotesModal() {
      this.lotes = await this.getCampaniasCultivoPropias();
      this.lotesElegidos = [];
      this.modalLotes = true;
      this.agregarLote(this.campaniaCultivo._id);
    },

    aceptLotesModal() {
      this.modalLotes = false;
      this.lotes = [];
      const actividades = [];
      this.lotesElegidos.forEach(l => {
        actividades.push({
          campaniaCultivo: l,
          fecha: this.fecha,
          tipo: "Siembra",
          densidad: this.densidad,
          espaciamiento: this.espaciamiento,
          variableSemilla: this.variableSemilla
        });
      });
      this.$emit("submit", {
        actividades: actividades,
        realizaFertilizacionLuegoSiembra: this.realizaFertilizacion
      });
    },

    cancelLotesModal() {
      this.modalLotes = false;
      this.lotes = [];
      this.lotesElegidos = [];
    },

    async getCampaniasCultivoPropias() {
      const campaniaActual = this.campaniaCultivo.campania ? this.campaniaCultivo.campania : {};
      const pers = this.personaActual;
      if (!pers._id) {
        return;
      }


      const query = {
        persona: pers._id,
        deleted: false
      };

      let idsLotes = [];
      const ifTrue = true;

      if (ifTrue) {
        const { data, error } = await Lotes.get({
          query: query,
          populate: "persona",
          sort: { updatedAt: -1 }
        });
        if (error) {
          const alert = await alertController.create({
            header: "Error",
            message: "Error obteniendo lotes.",
            buttons: [
              {
                text: "Aceptar"
              }
            ]
          });
          return alert.present();
        }
        idsLotes = data
          .filter(l => l._id !== this.campaniaCultivo.lote)
          .map(l => l._id);
      }
      const { data, error } = await CampaniaCultivos.get({
        query: { deleted: false, campania: campaniaActual, lote: { $in: idsLotes } },
        populate: JSON.stringify([
          { path: "cultivo" },
          { path: "variedadCultivo" },
          { path: "lote" },
          { path: "lote", populate: "establecimiento" },
        ]),
        sort: { updatedAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo lotes.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }

      const lotes = data.map(cc => {
        return {
          _id: cc._id,
          lote: cc.lote.nombre,
          establecimiento: cc.lote.establecimiento && cc.lote.establecimiento.nombre ? cc.lote.establecimiento.nombre : "",
          cultivo: cc.cultivo && cc.cultivo.nombre ? cc.cultivo.nombre : "",
          variedad:
            cc.variedadCultivo && cc.variedadCultivo.variedad
              ? cc.variedadCultivo.variedad
              : ""
        };
      }).sort((a, b) => {
        const extractParts = (str) => {
          const match = str.match(/(\d+)|(\D+)/g);
          return match ? match.map(part => isNaN(part) ? part : parseInt(part)) : [];
        };

        const compareParts = (partsA, partsB) => {
          for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
            if (partsA[i] !== partsB[i]) {
              return isNaN(partsA[i]) || isNaN(partsB[i]) ? partsA[i].toString().localeCompare(partsB[i].toString()) : partsA[i] - partsB[i];
            }
          }
          return partsA.length - partsB.length;
        };

        const compareFields = (fieldA, fieldB) => {
          const partsA = extractParts(fieldA);
          const partsB = extractParts(fieldB);
          return compareParts(partsA, partsB);
        };

        if (a.establecimiento !== b.establecimiento) {
          return compareFields(a.establecimiento, b.establecimiento);
        }
        if (a.lote !== b.lote) {
          return compareFields(a.lote, b.lote);
        }
        if (a.cultivo !== b.cultivo) {
          return compareFields(a.cultivo, b.cultivo);
        }
        return compareFields(a.variedad, b.variedad);
      });

      return lotes;
    },

    async submitEditOrCreate() {
      this.$emit("update:success", false);
      let error = "";
      if (!this.fecha) {
        error += "<li>Complete el campo <b>Fecha</b>." + `<br/></li>`;
      }

      if (!this.densidad) {
        error += "<li>Complete el campo <b>Densidad</b>." + `<br/></li>`;
      } else if (isNaN(this.densidad)) {
        error += "<li>El campo <b>Densidad</b> es numérico." + `<br/></li>`;
      } else if (this.densidad < 0) {
        error +=
          "<li>El campo <b>Densidad</b> debe ser un número positivo." +
          `<br/></li>`;
      } else {
        if (this.unidad === "semillas/ha") {
          this.densidad = this.densidad / 10000;
        }
      }

      if (!this.espaciamiento) {
        error += "<li>Complete el campo <b>Espaciamiento</b>." + `<br/></li>`;
      } else if (isNaN(this.espaciamiento)) {
        error +=
          "<li>El campo <b>Espaciamiento</b> es numérico." + `<br/></li>`;
      } else if (this.espaciamiento < 0) {
        error +=
          "<li>El campo <b>Espaciamiento</b> debe ser un número positivo." +
          `<br/></li>`;
      }

      if (!this.variableSemilla) {
        error +=
          "<li>Complete el campo <b>Hace Variable de Semilla?</b>." +
          `<br/></li>`;
      }

      if (!this.editing && !this.realizaFertilizacion) {
        error +=
          "<li>Elija una opción para el campo <b>Realiza Fertilización?</b>." +
          `<br/></li>`;
      }

      if (!error) {
        if (!this.editing && this.lotes && this.lotes.length) {
          const alert = await alertController.create({
            header: "Actividades",
            message:
              "Desea replicar esta actividad para otros lotes del establecimiento?",
            buttons: [
              {
                text: "Si",
                handler: async () => {
                  await this.openLotesModal();
                }
              },
              {
                text: "No",
                handler: () => {
                  this.cancelLotesModal();
                  this.$emit("submit", {
                    actividades: [
                      {
                        campaniaCultivo: this.campaniaCultivo,
                        fecha: this.fecha,
                        tipo: "Siembra",
                        densidad: this.densidad,
                        espaciamiento: this.espaciamiento,
                        variableSemilla: this.variableSemilla
                      }
                    ],
                    realizaFertilizacionLuegoSiembra: this.realizaFertilizacion
                  });
                }
              }
            ]
          });
          alert.present();
        } else {
          this.$emit("submit", {
            actividades: [
              {
                _id: this.actividad._id,
                campaniaCultivo: this.campaniaCultivo,
                fecha: this.fecha,
                tipo: "Siembra",
                densidad: this.densidad,
                espaciamiento: this.espaciamiento,
                variableSemilla: this.variableSemilla
              }
            ],
            realizaFertilizacionLuegoSiembra: this.realizaFertilizacion
          });
        }
      } else {
        const alert = await alertController.create({
          header: "Error al crear la actividad",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

ion-item {
  --pading-start: 8px;
}

ion-content {
  --background: transparent;
}

@media only screen and (min-width: 1024px) {
  #campoAngosto {
    width: 60% !important;
  }
}
</style>
