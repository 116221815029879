<template>
  <v-form @submit="submitEditOrCreate">
    <ion-item>
      <ion-label position="floating">Fecha</ion-label>
      <ion-datetime v-model="fecha" :value="fecha" display-timezone="Etc/GMT+3" display-format="DD/MM/YY"
        picker-format="DD/MM/YY" cancelText="Cancelar" doneText="Aceptar"></ion-datetime>
    </ion-item>
    <ion-item style="margin-bottom: 8px">
      <ion-label position="floating">Forma de Aplicación</ion-label>
      <ion-select :value="formaAplicacionFertilizacion" @ionChange="formaAplicacionFertilizacion = $event.target.value">
        <ion-select-option v-for="fa in formasAplicacion" :value="fa" :key="fa">
          {{ fa }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-label style="margin-left: 8px">
      Hace Variable de Fertilización?
    </ion-label>
    <ion-radio-group v-model="variableFertilizacion">
      <ion-grid fixed>
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-label style="margin-right: 8px">Si</ion-label>
            <ion-radio @click="variableFertilizacion = 'Si'" value="Si"></ion-radio>
          </ion-col>
          <ion-col size="auto">
            <ion-label style="margin-right: 8px">No</ion-label>
            <ion-radio @click="variableFertilizacion = 'No'" value="No"></ion-radio>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-radio-group>
    <div>
      <ion-list-header>
        <ion-text>Fertilizantes:</ion-text>
        <ion-fab-button fill="solid" color="primary" size="small" @click="createProductoModal">
          <ion-icon :md="icons.add" :ios="icons.add" color="light"></ion-icon>
        </ion-fab-button>
      </ion-list-header>
      <ion-list v-for="prod in prodsFertilizacion" :value="prod" :key="prod._id">
        <ion-item button="true" type="button" class="itemBorder">
          <ion-grid fixed>
            <ion-row class="ion-float-start" @click="goToEditProducto(prod)">
              <ion-col size="50">
                <b>
                  {{ prod.producto.nombre }}
                </b>
              </ion-col>
              <ion-col style="white-space: normal" size="auto">
                {{ prod.dosis + " Kg/Ha - " + prod.momento }}
              </ion-col>
            </ion-row>
            <ion-col>
              <ion-button class="ion-float-end" fill="clear" color="secondary" @click="deleteProductoLocal(prod)">
                <ion-icon :md="icons.trash" :ios="icons.trash"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-grid>
        </ion-item>
      </ion-list>
    </div>
    <ion-button fill="clear" size="large" color="danger" @click="$emit('delete')" v-if="editing">
      <ion-icon :md="icons.trash" :ios="icons.trash"></ion-icon>
    </ion-button>
    <ion-row class="ion-float-right">
      <ion-col size="auto">
        <ion-button fill="outline" class="ion-margin-top ion-float-end" color="medium" @click="cancel">
          Cancelar
        </ion-button>
      </ion-col>
      <ion-col size="auto">
        <ion-button class="ion-float-right ion-margin-top ion-margin-bottom" @click="submitEditOrCreate">
          Aceptar
        </ion-button>
      </ion-col>
    </ion-row>
  </v-form>

  <ion-modal :is-open="modalEditProducto" @didDismiss="modalEditProducto = false" :backdropDismiss="false">
    <ion-content :scroll-events="true">
      <ProductoFertilizanteForm :error="error" :productoFertilizacion="selectedProducto" @cancel="cancelProd"
        @submit="addProducto" @delete="deleteProducto" class="modal-wrapper" />
    </ion-content>
  </ion-modal>

  <ion-modal :is-open="modalLotes" @didDismiss="modalLotes = false" :backdropDismiss="false">
    <ion-content :scroll-events="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title>Otros lotes a aplicar Actividad</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list v-for="l in lotes" :value="l" :key="l._id">
            <ion-item button="false" class="itemBorder">
              <ion-checkbox @ionChange="agregarLote(l._id)"></ion-checkbox>
              <ion-label class="ion-text-capitalize" style="margin-left: 8px; white-space: normal;">
                {{ l.establecimiento + " - " + l.lote + " (" + l.cultivo + " - " + l.variedad + ")" }}
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-row class="ion-float-right">
            <ion-col size="auto">
              <ion-button fill="outline" class="ion-margin-top ion-float-end" color="medium" @click="cancelLotesModal">
                Cancelar
              </ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button class="ion-float-right ion-margin-top ion-margin-bottom" @click="aceptLotesModal">
                Aceptar
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-modal>
</template>

<script>
import * as V from "vee-validate/dist/vee-validate";
import {
  IonDatetime,
  //IonInput,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  IonLabel,
  alertController,
  IonList,
  IonContent,
  IonListHeader,
  IonFabButton,
  IonText,
  IonGrid,
  IonModal,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonRow,
  IonCol,
  IonIcon,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonCardTitle,
  //IonRow,
  //IonGrid,
  IonButton
} from "@ionic/vue";
import { mapState, mapActions } from "vuex";
import { trashOutline, addOutline } from "ionicons/icons";
import ProductoFertilizanteForm from "@/components/ProductoFertilizanteForm.vue";
import http from "@/api/http";
import { ProductosFertilizacion, Lotes, CampaniaCultivos } from "@/api";

export default {
  components: {
    IonDatetime,
    //IonInput,
    IonText,
    IonCheckbox,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonFabButton,
    IonList,
    IonContent,
    IonListHeader,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonCardTitle,
    IonRow,
    IonCol,
    IonGrid,
    IonModal,
    IonButton,
    IonIcon,
    ProductoFertilizanteForm,
    VForm: V.Form
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    campaniaCultivo: { type: Object, default: () => ({}) },
    actividad: { type: Object, default: () => ({}) },
    personaActual: { type: Object, default: () => ({}) }
  },

  data: () => ({
    reset: false,
    fecha: "",
    selectedProducto: {},
    deleteAct: false,
    modalEditProducto: false,
    modalLotes: false,
    formaAplicacionFertilizacion: "",
    formasAplicacion: ["Chorreado", "Voleado", "Incorporado"],
    variableFertilizacion: "",
    prodsFertilizacion: [],
    prodFertilizacionAux: {},
    lotes: [],
    lotesElegidos: [],
    confirmarSubmit: false,
    icons: {
      trash: trashOutline,
      add: addOutline
    }
    //hora: ""
    //error: "",
  }),

  computed: {
    ...mapState(["user"]),
    editing() {
      return this.actividad._id;
    }
  },

  async mounted() {
    await this.getMe();
    this.dataSync();
  },

  watch: {
    success(val) {
      if (val) {
        this.cleaner();
      }
    },
    actividad() {
      this.cleaner();
      this.dataSync();
    }
  },

  methods: {
    ...mapActions(["getMe"]),
    cleaner() {
      this.fecha = "";
      this.formaAplicacionFertilizacion = "";
      this.variableFertilizacion = "";
    },

    goToEditProducto(producto) {
      this.selectedProducto = producto;
      this.modalEditProducto = true;
    },

    agregarLote(id) {
      this.lotesElegidos.push(id);
    },

    async openLotesModal() {
      this.lotes = await this.getCampaniasCultivoPropias();
      this.lotesElegidos = [];
      this.modalLotes = true;
    },

    createProductoModal() {
      this.selectedProducto = {};
      this.modalEditProducto = true;
    },
    dataSync() {
      this.prodsFertilizacion = this.editing
        ? this.actividad.productosFertilizacion
        : [];
      this.prodFertilizacionAux = {};
      if (this.editing) {
        this.fecha = new Date(this.actividad.fecha).toISOString();
        this.formaAplicacionFertilizacion = this.actividad.formaAplicacionFertilizacion;
        this.variableFertilizacion = this.actividad.variableFertilizacion;
      } else {
        setTimeout(async () => {
          this.lotes = await this.getCampaniasCultivoPropias();
        }, 1500);
      }
      if (this.$route.params.fecha.toString() !== "sf") {
        this.fecha = new Date(this.$route.params.fecha).toISOString();
      }
    },

    async deleteProducto() {
      try {
        const alert = await alertController.create({
          header: "Producto",
          message: "¿Desea eliminar este producto?",
          buttons: [
            {
              text: "Si",
              handler: async () => {
                const { error } = await ProductosFertilizacion.deleteReal(
                  this.selectedProducto
                );
                if (!error) {
                  const alert = await alertController.create({
                    header: "Producto",
                    message: "Producto Eliminado",
                    buttons: [
                      {
                        text: "Aceptar",
                        handler: async () => {
                          this.modalEditProducto = false;
                          this.selectedProducto = {};
                          window.location.reload();
                        }
                      }
                    ]
                  });
                  alert.present();
                }
              }
            },
            {
              text: "No"
            }
          ]
        });
        alert.present();
      } catch (e) {
        console.log(e);
      }
    },

    cancelProd() {
      this.modalEditProducto = false;
      this.selectedProducto = {};
    },

    async aceptLotesModal() {
      this.modalLotes = false;
      this.lotes = [];
      const actividades = [];
      actividades.push({
        campaniaCultivo: this.campaniaCultivo,
        fecha: this.fecha,
        tipo: "Fertilización",
        formaAplicacionFertilizacion: this.formaAplicacionFertilizacion,
        variableFertilizacion: this.variableFertilizacion,
        productosFertilizacion: this.prodFertilizacionAux
      });
      for (let l of this.lotesElegidos) {
        const { data } = await http.post(
          "/api/save-productos",
          this.prodsFertilizacion
        );
        actividades.push({
          campaniaCultivo: l,
          fecha: this.fecha,
          tipo: "Fertilización",
          formaAplicacionFertilizacion: this.formaAplicacionFertilizacion,
          variableFertilizacion: this.variableFertilizacion,
          productosFertilizacion: data
        });
      }
      this.$emit("submit", {
        actividades
      });
    },

    cancelLotesModal() {
      this.modalLotes = false;
      this.lotes = [];
      this.lotesElegidos = [];
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    async deleteProductoLocal(prod) {
      const alert = await alertController.create({
        header: "Producto",
        message: "¿Desea eliminar este producto?",
        buttons: [
          {
            text: "Si",
            handler: async () => {
              this.prodsFertilizacion = this.prodsFertilizacion.filter(
                p =>
                  p.producto !== prod.producto ||
                  p.momento !== prod.momento ||
                  p.dosis !== prod.dosis
              );
            }
          },
          {
            text: "No"
          }
        ]
      });
      alert.present();
    },

    async addProducto({ productoFertilizacion }) {
      if (productoFertilizacion._id) {
        this.prodsFertilizacion = this.prodsFertilizacion.map(p => {
          if (p._id === productoFertilizacion._id) {
            return productoFertilizacion;
          }
          return p;
        });
      } else {
        this.prodsFertilizacion.push(productoFertilizacion);
      }
      this.modalEditProducto = false;
      this.selectedProducto = {};
    },

    async getCampaniasCultivoPropias() {
      const campaniaActual = this.campaniaCultivo.campania ? this.campaniaCultivo.campania : {};
      const pers = this.personaActual;
      if (!pers._id) {
        return;
      }

      const query = {
        persona: pers._id,
        deleted: false
      };

      let idsLotes = [];
      const ifTrue = true;

      if (ifTrue) {
        const { data, error } = await Lotes.get({
          query: query,
          populate: "persona",
          sort: { updatedAt: -1 }
        });
        if (error) {
          const alert = await alertController.create({
            header: "Error",
            message: "Error obteniendo lotes.",
            buttons: [
              {
                text: "Aceptar"
              }
            ]
          });
          return alert.present();
        }
        idsLotes = data
          .filter(l => l._id !== this.campaniaCultivo.lote)
          .map(l => l._id);
      }
      const { data, error } = await CampaniaCultivos.get({
        query: { deleted: false, campania: campaniaActual, lote: { $in: idsLotes } },
        populate: JSON.stringify([
          { path: "cultivo" },
          { path: "variedadCultivo" },
          { path: "lote" },
          { path: "lote", populate: "establecimiento" },
        ]),
        sort: { updatedAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo lotes.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }

      const lotes = data.map(cc => {
        return {
          _id: cc._id,
          lote: cc.lote.nombre,
          establecimiento: cc.lote.establecimiento && cc.lote.establecimiento.nombre ? cc.lote.establecimiento.nombre : "",
          cultivo: cc.cultivo && cc.cultivo.nombre ? cc.cultivo.nombre : "",
          variedad:
            cc.variedadCultivo && cc.variedadCultivo.variedad
              ? cc.variedadCultivo.variedad
              : ""
        };
      }).sort((a, b) => {
        const extractParts = (str) => {
          const match = str.match(/(\d+)|(\D+)/g);
          return match ? match.map(part => isNaN(part) ? part : parseInt(part)) : [];
        };

        const compareParts = (partsA, partsB) => {
          for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
            if (partsA[i] !== partsB[i]) {
              return isNaN(partsA[i]) || isNaN(partsB[i]) ? partsA[i].toString().localeCompare(partsB[i].toString()) : partsA[i] - partsB[i];
            }
          }
          return partsA.length - partsB.length;
        };

        const compareFields = (fieldA, fieldB) => {
          const partsA = extractParts(fieldA);
          const partsB = extractParts(fieldB);
          return compareParts(partsA, partsB);
        };

        if (a.establecimiento !== b.establecimiento) {
          return compareFields(a.establecimiento, b.establecimiento);
        }
        if (a.lote !== b.lote) {
          return compareFields(a.lote, b.lote);
        }
        if (a.cultivo !== b.cultivo) {
          return compareFields(a.cultivo, b.cultivo);
        }
        return compareFields(a.variedad, b.variedad);
      });

      return lotes;
    },

    async submitEditOrCreate() {
      this.$emit("update:success", false);
      const { data, error } = await http.post(
        "/api/save-productos",
        this.prodsFertilizacion
      );

      let error2 = "";
      if (error) {
        error2 += "<li>Error al guardar los productos</b>." + `<br/></li>`;
      }
      if (!this.fecha) {
        error2 += "<li>Complete el campo <b>Fecha</b>." + `<br/></li>`;
      }
      if (!this.variableFertilizacion) {
        error2 +=
          "<li>Complete el campo <b>Hace Variable de Fertilización?</b>." +
          `<br/></li>`;
      }
      if (!this.formaAplicacionFertilizacion) {
        error2 +=
          "<li>Elija una opción para el campo <b>Forma de Aplicación</b>." +
          `<br/></li>`;
      }
      if (!error2) {
        if (!this.editing && this.lotes && this.lotes.length) {
          const alert = await alertController.create({
            header: "Actividades",
            message:
              "Desea replicar esta actividad para otros lotes del establecimiento?",
            buttons: [
              {
                text: "Si",
                handler: async () => {
                  await this.openLotesModal();
                  this.prodFertilizacionAux = data;
                }
              },
              {
                text: "No",
                handler: () => {
                  this.cancelLotesModal();
                  const actividades = [];
                  actividades.push({
                    campaniaCultivo: this.campaniaCultivo,
                    fecha: this.fecha,
                    tipo: "Fertilización",
                    formaAplicacionFertilizacion: this
                      .formaAplicacionFertilizacion,
                    variableFertilizacion: this.variableFertilizacion,
                    productosFertilizacion: data
                  });
                  this.$emit("submit", {
                    actividades
                  });
                }
              }
            ]
          });
          alert.present();
        } else {
          const actividades = [];
          actividades.push({
            _id: this.actividad._id,
            campaniaCultivo: this.campaniaCultivo,
            fecha: this.fecha,
            tipo: "Fertilización",
            formaAplicacionFertilizacion: this.formaAplicacionFertilizacion,
            variableFertilizacion: this.variableFertilizacion,
            productosFertilizacion: data
          });
          this.$emit("submit", {
            actividades
          });
        }
      } else {
        const alert = await alertController.create({
          header: "Error al crear la actividad",
          message: error2,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

.smallFont {
  font-size: small;
}

ion-item {
  --pading-start: 8px;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

ion-content {
  --background: transparent;
}
</style>
