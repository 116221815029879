<template>
  <v-form @submit="submitEditOrCreate">
    <ion-item>
      <ion-label position="floating">
        Fecha
      </ion-label>
      <ion-datetime
        v-model="fecha"
        :value="fecha"
        display-timezone="Etc/GMT+3"
        display-format="DD/MM/YY"
        picker-format="DD/MM/YY"
        cancelText="Cancelar"
        doneText="Aceptar"
      ></ion-datetime>
    </ion-item>

    <ion-item>
      <ion-grid fixed>
        <ion-row class="ion-align-items-center ion-margin-top">
          <ion-text
            style="font-weight:bold; font-size:20px; white-space: normal;"
          >
            Rendimiento
          </ion-text>
          <ion-button fill="clear" size="small" @click="modalEditHandler">
            <ion-icon
              :md="icons.edit"
              :ios="icons.edit"
              color="dark"
            ></ion-icon>
          </ion-button>
        </ion-row>
        <ion-row>
          <ion-text>{{ "Rendimiento: " }}</ion-text>
          <ion-text
            style="margin-left: 8px; font-weight:bold;"
            color="primary"
            v-if="rendimiento"
          >
            {{ rendimiento + " kg/ha" }}
          </ion-text>
        </ion-row>
        <ion-row>
          <ion-text>{{ "Humedad: " }}</ion-text>
          <ion-text
            style="margin-left: 8px; font-weight:bold;"
            color="primary"
            v-if="humedad"
          >
            {{ humedad + " %" }}
          </ion-text>
        </ion-row>
        <ion-row>
          <ion-text>{{ "Proteína: " }}</ion-text>
          <ion-text
            style="margin-left: 8px; font-weight:bold;"
            color="primary"
            v-if="proteina"
          >
            {{ proteina + " %" }}
          </ion-text>
        </ion-row>
        <ion-row>
          <ion-text>{{ "Calibre: " }}</ion-text>
          <ion-text
            style="margin-left: 8px; font-weight:bold;"
            color="primary"
            v-if="calibre"
          >
            {{ calibre + " %" }}
          </ion-text>
        </ion-row>
        <ion-row>
          <ion-text>{{ "Gluten: " }}</ion-text>
          <ion-text
            style="margin-left: 8px; font-weight:bold;"
            color="primary"
            v-if="gluten"
          >
            {{ gluten + " %" }}
          </ion-text>
        </ion-row>
        <ion-row>
          <ion-text>{{ "Peso Hectolítrico: " }}</ion-text>
          <ion-text
            style="margin-left: 8px; font-weight:bold;"
            color="primary"
            v-if="pesoHectolitrico"
          >
            {{ pesoHectolitrico + " kg/100L" }}
          </ion-text>
        </ion-row>
        <ion-row>
          <ion-text>{{ "Materia Grasa: " }}</ion-text>
          <ion-text
            style="margin-left: 8px; font-weight:bold;"
            color="primary"
            v-if="materiaGrasa"
          >
            {{ materiaGrasa + " %" }}
          </ion-text>
        </ion-row>
        <ion-row>
          <ion-text>{{ "Ácido Oleico: " }}</ion-text>
          <ion-text
            style="margin-left: 8px; font-weight:bold;"
            color="primary"
            v-if="acidoOleico"
          >
            {{ acidoOleico + " %" }}
          </ion-text>
        </ion-row>
      </ion-grid>
    </ion-item>

    <ion-modal
      :is-open="modalEdit"
      @didDismiss="modalEdit = false"
      :backdropDismiss="false"
      :showBackdrop="true"
    >
      <ion-content :scroll-events="true" id="campoAngosto">
        <ion-row class="ion-justify-content-center">
          <ion-card>
            <ion-card-title
              class="ion-margin-top"
              style="margin-left:16px; font-weight: bold; white-space: normal;"
            >
              Rendimiento
            </ion-card-title>
            <ion-card-content>
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <ion-item>
                    <ion-label position="floating">Rendimiento</ion-label>
                    <ion-input
                      v-model="modal.rendimiento"
                      autocapitalize="off"
                      type="text"
                      inputmode="text"
                      autocomplete="off"
                      placeholder="kg/ha"
                    ></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size="auto" style="margin-top:24px">
                  <ion-label>
                    Kg/Ha
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <ion-item>
                    <ion-label position="floating">Humedad</ion-label>
                    <ion-input
                      v-model="modal.humedad"
                      autocapitalize="off"
                      type="text"
                      inputmode="text"
                      autocomplete="off"
                      placeholder="porcentaje(%)"
                    ></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size="auto" style="margin-top:24px">
                  <ion-label>
                    %
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <ion-item>
                    <ion-label position="floating">Proteína</ion-label>
                    <ion-input
                      v-model="modal.proteina"
                      autocapitalize="off"
                      type="text"
                      inputmode="text"
                      autocomplete="off"
                      placeholder="porcentaje(%)"
                    ></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size="auto" style="margin-top:24px">
                  <ion-label>
                    %
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <ion-item>
                    <ion-label position="floating">Calibre</ion-label>
                    <ion-input
                      v-model="modal.calibre"
                      autocapitalize="off"
                      type="text"
                      inputmode="text"
                      autocomplete="off"
                      placeholder="porcentaje(%)"
                    ></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size="auto" style="margin-top:24px">
                  <ion-label>
                    %
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <ion-item>
                    <ion-label position="floating">Gluten</ion-label>
                    <ion-input
                      v-model="modal.gluten"
                      autocapitalize="off"
                      type="text"
                      inputmode="text"
                      autocomplete="off"
                      placeholder="porcentaje(%)"
                    ></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size="auto" style="margin-top:24px">
                  <ion-label>
                    %
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <ion-item>
                    <ion-label position="floating">Peso Hectolítrico</ion-label>
                    <ion-input
                      v-model="modal.pesoHectolitrico"
                      autocapitalize="off"
                      type="text"
                      inputmode="text"
                      autocomplete="off"
                      placeholder="kg/100L"
                    ></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size="auto" style="margin-top:24px">
                  <ion-label>
                    Kg/100L
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <ion-item>
                    <ion-label position="floating">Materia Grasa</ion-label>
                    <ion-input
                      v-model="modal.materiaGrasa"
                      autocapitalize="off"
                      type="text"
                      inputmode="text"
                      autocomplete="off"
                      placeholder="porcentaje(%)"
                    ></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size="auto" style="margin-top:24px">
                  <ion-label>
                    %
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <ion-item>
                    <ion-label position="floating">Ácido Oleico</ion-label>
                    <ion-input
                      v-model="modal.acidoOleico"
                      autocapitalize="off"
                      type="text"
                      inputmode="text"
                      autocomplete="off"
                      placeholder="porcentaje(%)"
                    ></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size="auto" style="margin-top:24px">
                  <ion-label>
                    %
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row class="ion-float-right">
                <ion-col>
                  <ion-button
                    fill="outline"
                    class="ion-margin-top"
                    color="medium"
                    @click="cancelRinde"
                  >
                    Cancelar
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button
                    class="ion-float-right ion-margin-top"
                    @click="aceptRinde"
                  >
                    Aceptar
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </ion-card>
        </ion-row>
      </ion-content>
    </ion-modal>

    <ion-modal
      :is-open="modalLotes"
      @didDismiss="modalLotes = false"
      :backdropDismiss="false"
    >
      <ion-content :scroll-events="true">
        <ion-card>
          <ion-card-header>
            <ion-card-title>Otros lotes a aplicar Actividad</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list v-for="l in lotes" :value="l" :key="l._id">
              <ion-item button="false" class="itemBorder">
                <ion-checkbox @ionChange="agregarLote(l._id)"></ion-checkbox>
                <ion-label
                  class="ion-text-capitalize"
                  style="margin-left: 8px; white-space: normal;"
                >
                  {{ l.establecimiento + " - " + l.lote + " (" + l.cultivo + " - " + l.variedad + ")" }}
                </ion-label>
              </ion-item>
            </ion-list>
            <ion-row class="ion-float-right">
              <ion-col size="auto">
                <ion-button
                  fill="outline"
                  class="ion-margin-top ion-float-end"
                  color="medium"
                  @click="cancelLotesModal"
                >
                  Cancelar
                </ion-button>
              </ion-col>
              <ion-col size="auto">
                <ion-button
                  class="ion-float-right ion-margin-top ion-margin-bottom"
                  @click="aceptLotesModal"
                >
                  Aceptar
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </ion-content>
    </ion-modal>

    <ion-button
      fill="clear"
      size="large"
      color="danger"
      @click="$emit('delete')"
      v-if="editing"
    >
      <ion-icon :md="icons.trash" :ios="icons.trash"></ion-icon>
    </ion-button>
    <ion-row class="ion-float-right">
      <ion-col size="auto">
        <ion-button
          fill="outline"
          class="ion-margin-top ion-float-end"
          color="medium"
          @click="cancel"
        >
          Cancelar
        </ion-button>
      </ion-col>
      <ion-col size="auto">
        <ion-button
          class="ion-float-right ion-margin-top ion-margin-bottom"
          @click="submitEditOrCreate"
        >
          Aceptar
        </ion-button>
      </ion-col>
    </ion-row>
  </v-form>
</template>

<script>
import * as V from "vee-validate/dist/vee-validate";
import {
  IonDatetime,
  IonInput,
  IonLabel,
  alertController,
  IonText,
  //IonCardHeader,
  IonItem,
  /* IonImg,
  IonThumbnail, */
  IonCard,
  IonCheckbox,
  IonCardHeader,
  IonCardContent,
  IonIcon,
  IonCardTitle,
  IonRow,
  IonCol,
  IonModal,
  IonGrid,
  IonButton
} from "@ionic/vue";
import { mapState, mapActions } from "vuex";
import { trashOutline, pencilOutline } from "ionicons/icons";
import { Lotes, CampaniaCultivos, Rinde } from "@/api";

export default {
  components: {
    IonDatetime,
    IonInput,
    IonText,
    //IonCardHeader,
    IonLabel,
    IonItem,
    IonCardTitle,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCardHeader,
    //IonRow,
    /* IonImg,
    IonThumbnail,*/
    IonGrid,
    IonModal,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    VForm: V.Form
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    campaniaCultivo: { type: Object, default: () => ({}) },
    actividad: { type: Object, default: () => ({}) },
    tipo: { type: String, default: "" },
    personaActual: { type: Object, default: () => ({}) }
  },

  data: () => ({
    fecha: "",
    rinde: {},
    modalEdit: false,

    rendimiento: "",
    humedad: "",
    proteina: "",
    calibre: "",
    gluten: "",
    pesoHectolitrico: "",
    materiaGrasa: "",
    acidoOleico: "",

    modalLotes: false,

    modal: {
      rendimiento: "",
      humedad: "",
      proteina: "",
      calibre: "",
      gluten: "",
      pesoHectolitrico: "",
      materiaGrasa: "",
      acidoOleico: ""
    },

    lotes: [],
    lotesElegidos: [],
    confirmarSubmit: false,

    deleteAct: false,
    icons: {
      trash: trashOutline,
      edit: pencilOutline
    }
    //hora: ""
    //error: "",
  }),

  computed: {
    ...mapState(["user"]),
    editing() {
      return this.actividad._id;
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
  },

  watch: {
    success(val) {
      if (val) {
        this.cleaner();
      }
    },
    actividad() {
      this.cleaner();
      this.dataSync();
    }
  },

  methods: {
    ...mapActions(["getMe"]),
    cleaner() {
      this.fecha = new Date().toISOString();
      this.rendimiento = "";
      this.humedad = "";
      this.proteina = "";
      this.calibre = "";
      this.gluten = "";
      this.pesoHectolitrico = "";
      this.materiaGrasa = "";
      this.acidoOleico = "";
      this.modalCleaner();
      //this.error = "";
    },

    agregarLote(id) {
      this.lotesElegidos.push(id);
    },

    async openLotesModal() {
      this.lotes = await this.getCampaniasCultivoPropias();
      this.lotesElegidos = [];
      this.modalLotes = true;
      this.agregarLote(this.campaniaCultivo._id);
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    cancelRinde() {
      this.modalEdit = false;
      this.modalCleaner();
    },

    aceptLotesModal() {
      this.modalLotes = false;
      this.lotes = [];
      const actividades = [];
      this.lotesElegidos.forEach(l => {
        actividades.push({
          campaniaCultivo: l,
          fecha: this.fecha,
          tipo: this.tipo
        });
      });
      this.$emit("submit", {
        actividades: actividades,
        rinde: {
          _id: this.rinde._id,
          rendimiento: this.modal.rendimiento,
          humedad: this.modal.humedad,
          proteina: this.modal.proteina,
          calibre: this.modal.calibre,
          gluten: this.modal.gluten,
          pesoHectolitrico: this.modal.pesoHectolitrico,
          materiaGrasa: this.modal.materiaGrasa,
          acidoOleico: this.modal.acidoOleico,
          actividad:
            this.actividad && this.actividad._id ? this.actividad : undefined,
          campaniaCultivo: this.campaniaCultivo
        }
      });
    },

    cancelLotesModal() {
      this.modalLotes = false;
      this.lotes = [];
      this.lotesElegidos = [];
    },

    fillFieldsModal() {
      this.modal.rendimiento = this.rendimiento;
      this.modal.humedad = this.humedad;
      this.modal.proteina = this.proteina;
      this.modal.calibre = this.calibre;
      this.modal.gluten = this.gluten;
      this.modal.pesoHectolitrico = this.pesoHectolitrico;
      this.modal.materiaGrasa = this.materiaGrasa;
      this.modal.acidoOleico = this.acidoOleico;
    },

    modalCleaner() {
      this.modal.rendimiento = "";
      this.modal.humedad = "";
      this.modal.proteina = "";
      this.modal.calibre = "";
      this.modal.gluten = "";
      this.modal.pesoHectolitrico = "";
      this.modal.materiaGrasa = "";
      this.modal.acidoOleico = "";
    },

    async dataSync() {
      if (this.editing) {
        this.fecha = new Date(this.actividad.fecha).toISOString();
        this.rinde = await this.getRinde();
        if (this.rinde && this.rinde._id) {
          this.rendimiento = this.rinde.rendimiento;
          this.humedad = this.rinde.humedad;
          this.proteina = this.rinde.proteina;
          this.calibre = this.rinde.calibre;
          this.gluten = this.rinde.gluten;
          this.pesoHectolitrico = this.rinde.pesoHectolitrico;
          this.materiaGrasa = this.rinde.materiaGrasa;
          this.acidoOleico = this.rinde.acidoOleico;
          this.fillFieldsModal();
        } else {
          this.rendimiento = "";
          this.humedad = "";
          this.proteina = "";
          this.calibre = "";
          this.gluten = "";
          this.pesoHectolitrico = "";
          this.materiaGrasa = "";
          this.acidoOleico = "";
        }
      } else {
        setTimeout(async () => {
          this.lotes = await this.getCampaniasCultivoPropias();
        }, 1500);
      }
    },

    async getCampaniasCultivoPropias() {
      const campaniaActual = this.campaniaCultivo.campania ? this.campaniaCultivo.campania : {}; 
      const pers = this.personaActual;
      if (!pers._id) {
        return;
      }

      const query = {
        persona: pers._id,
        deleted: false
      };

      let idsLotes = [];
      const ifTrue = true;

      if (ifTrue) {
        const { data, error } = await Lotes.get({
          query: query,
          populate: "persona",
          sort: { updatedAt: -1 }
        });
        if (error) {
          const alert = await alertController.create({
            header: "Error",
            message: "Error obteniendo lotes.",
            buttons: [
              {
                text: "Aceptar"
              }
            ]
          });
          return alert.present();
        }
        idsLotes = data
          .filter(l => l._id !== this.campaniaCultivo.lote)
          .map(l => l._id);
      }

      const { data, error } = await CampaniaCultivos.get({
        query: { deleted: false, campania: campaniaActual, lote: { $in: idsLotes } },
        populate: JSON.stringify([
          { path: "cultivo" },
          { path: "variedadCultivo" },
          { path: "lote" },
          { path: "lote", populate: "establecimiento" },
        ]),
        sort: { updatedAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo lotes.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }

      const lotes = data.map(cc => {
        return {
          _id: cc._id,
          lote: cc.lote.nombre,
          establecimiento: cc.lote.establecimiento && cc.lote.establecimiento.nombre ? cc.lote.establecimiento.nombre : "",
          cultivo: cc.cultivo && cc.cultivo.nombre ? cc.cultivo.nombre : "",
          variedad:
            cc.variedadCultivo && cc.variedadCultivo.variedad
              ? cc.variedadCultivo.variedad
              : ""
        };
      }).sort((a, b) => {
        const extractParts = (str) => {
          const match = str.match(/(\d+)|(\D+)/g);
          return match ? match.map(part => isNaN(part) ? part : parseInt(part)) : [];
        };

        const compareParts = (partsA, partsB) => {
          for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
            if (partsA[i] !== partsB[i]) {
              return isNaN(partsA[i]) || isNaN(partsB[i]) ? partsA[i].toString().localeCompare(partsB[i].toString()) : partsA[i] - partsB[i];
            }
          }
          return partsA.length - partsB.length;
        };

        const compareFields = (fieldA, fieldB) => {
          const partsA = extractParts(fieldA);
          const partsB = extractParts(fieldB);
          return compareParts(partsA, partsB);
        };

        if (a.establecimiento !== b.establecimiento) {
          return compareFields(a.establecimiento, b.establecimiento);
        }
        if (a.lote !== b.lote) {
          return compareFields(a.lote, b.lote);
        }
        if (a.cultivo !== b.cultivo) {
          return compareFields(a.cultivo, b.cultivo);
        }
        return compareFields(a.variedad, b.variedad);
      });
      
      return lotes;
    },

    async getRinde() {
      const { data, error } = await Rinde.get({
        query: {
          campaniaCultivo: this.campaniaCultivo._id,
          actividad: this.actividad._id
        },
        sort: { createdAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo datos de Rinde.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      if (data.length) {
        return data[0];
      }
      return data;
    },

    async submitEditOrCreate() {
      this.$emit("update:success", false);
      let error = "";
      if (!this.fecha) {
        error += "<li>Complete el campo <b>Fecha</b>." + `<br/></li>`;
      }
      if (!this.tipo) {
        error += "<li>Elija un Tipo de<b>Actividad</b>." + `<br/></li>`;
      }

      if (!error) {
        if (!this.editing && this.lotes && this.lotes.length) {
          const alert = await alertController.create({
            header: "Actividades",
            message:
              "Desea replicar esta actividad para otros lotes del establecimiento?",
            buttons: [
              {
                text: "Si",
                handler: async () => {
                  await this.openLotesModal();
                }
              },
              {
                text: "No",
                handler: () => {
                  this.cancelLotesModal();
                  this.$emit("submit", {
                    actividades: [
                      {
                        campaniaCultivo: this.campaniaCultivo,
                        fecha: this.fecha,
                        tipo: this.tipo
                      }
                    ],
                    rinde: {
                      _id: this.rinde._id,
                      rendimiento: this.modal.rendimiento,
                      humedad: this.modal.humedad,
                      proteina: this.modal.proteina,
                      calibre: this.modal.calibre,
                      gluten: this.modal.gluten,
                      pesoHectolitrico: this.modal.pesoHectolitrico,
                      materiaGrasa: this.modal.materiaGrasa,
                      acidoOleico: this.modal.acidoOleico,
                      actividad:
                        this.actividad && this.actividad._id
                          ? this.actividad
                          : undefined,
                      campaniaCultivo: this.campaniaCultivo
                    }
                  });
                }
              }
            ]
          });
          alert.present();
        } else {
          this.$emit("submit", {
            actividades: [
              {
                _id: this.actividad._id,
                campaniaCultivo: this.campaniaCultivo,
                fecha: this.fecha,
                tipo: this.tipo
              }
            ],
            rinde: {
              _id: this.rinde._id,
              rendimiento: this.modal.rendimiento,
              humedad: this.modal.humedad,
              proteina: this.modal.proteina,
              calibre: this.modal.calibre,
              gluten: this.modal.gluten,
              pesoHectolitrico: this.modal.pesoHectolitrico,
              materiaGrasa: this.modal.materiaGrasa,
              acidoOleico: this.modal.acidoOleico,
              actividad:
                this.actividad && this.actividad._id
                  ? this.actividad
                  : undefined,
              campaniaCultivo: this.campaniaCultivo
            }
          });
        }
      } else {
        const alert = await alertController.create({
          header: "Error al crear la actividad",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    async aceptRinde() {
      let error = "";
      if (this.modal.rendimiento) {
        if (isNaN(this.modal.rendimiento)) {
          error +=
            "<li>El campo <b>Rendimiento</b> es numérico." + `<br/></li>`;
        } else if (this.modal.rendimiento < 0) {
          error +=
            "<li>El campo <b>Rendimiento</b> debe ser un número positivo." +
            `<br/></li>`;
        }
      }

      if (this.modal.humedad) {
        if (isNaN(this.modal.humedad)) {
          error += "<li>El campo <b>Humedad</b> es numérico." + `<br/></li>`;
        } else if (this.modal.humedad < 0 || this.modal.humedad > 100) {
          error +=
            "<li>El campo <b>Humedad</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (this.modal.proteina) {
        if (isNaN(this.modal.proteina)) {
          error += "<li>El campo <b>Proteína</b> es numérico." + `<br/></li>`;
        } else if (this.modal.proteina < 0 || this.modal.proteina > 100) {
          error +=
            "<li>El campo <b>Proteína</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (this.modal.calibre) {
        if (isNaN(this.modal.calibre)) {
          error += "<li>El campo <b>Calibre</b> es numérico." + `<br/></li>`;
        } else if (this.modal.calibre < 0 || this.modal.calibre > 100) {
          error +=
            "<li>El campo <b>Calibre</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (this.modal.gluten) {
        if (isNaN(this.modal.gluten)) {
          error += "<li>El campo <b>Gluten</b> es numérico." + `<br/></li>`;
        } else if (this.modal.gluten < 0 || this.modal.gluten > 100) {
          error +=
            "<li>El campo <b>Gluten</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (this.modal.pesoHectolitrico) {
        if (isNaN(this.modal.pesoHectolitrico)) {
          error +=
            "<li>El campo <b>Peso Hectolítrico</b> es numérico." + `<br/></li>`;
        } else if (this.modal.pesoHectolitrico < 0) {
          error +=
            "<li>El campo <b>Peso Hectolítrico</b> debe ser un número positivo." +
            `<br/></li>`;
        }
      }

      if (this.modal.materiaGrasa) {
        if (isNaN(this.modal.materiaGrasa)) {
          error +=
            "<li>El campo <b>Materia Grasa</b> es numérico." + `<br/></li>`;
        } else if (
          this.modal.materiaGrasa < 0 ||
          this.modal.materiaGrasa > 100
        ) {
          error +=
            "<li>El campo <b>Materia Grasa</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (this.modal.acidoOleico) {
        if (isNaN(this.modal.acidoOleico)) {
          error +=
            "<li>El campo <b>Acido Oleico</b> es numérico." + `<br/></li>`;
        } else if (this.modal.acidoOleico < 0 || this.modal.acidoOleico > 100) {
          error +=
            "<li>El campo <b>Acido Oleico</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }
      if (!error) {
        this.rendimiento = this.modal.rendimiento;
        this.humedad = this.modal.humedad;
        this.proteina = this.modal.proteina;
        this.calibre = this.modal.calibre;
        this.gluten = this.modal.gluten;
        this.pesoHectolitrico = this.modal.pesoHectolitrico;
        this.materiaGrasa = this.modal.materiaGrasa;
        this.acidoOleico = this.modal.acidoOleico;
        this.modalEdit = false;
      } else {
        const alert = await alertController.create({
          header: "Error al crear Rinde",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    modalEditHandler() {
      this.fillFieldsModal();
      this.modalEdit = true;
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

ion-item {
  --pading-start: 8px;
}

ion-content {
  --background: transparent;
}

@media only screen and (min-width: 1024px) {
  #campoAngosto {
    width: 60% !important;
  }
}
</style>
