<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <div id="container">
        <ion-item
          id="item"
          class="ion-margin-top margen9pxLados itemColor"
          size="auto"
        >
          <ion-row width="200px">
            <ion-col>
              <ion-text color="dark">
                Lote
              </ion-text>

              <br />
              <ion-text
                class="ion-text-capitalize negrita"
                style="white-space: normal"
                color="dark"
              >
                {{ nombreLoteCiudad }}
              </ion-text>
              <br />
              <ion-text color="secondary">
                <i>{{ "Sup. " + hectareasLote + " Ha" }}</i>
              </ion-text>
              <br />
              <ion-text color="dark">
                Establecimiento:
              </ion-text>
              <ion-text
                class="ion-text-capitalize"
                style="white-space: normal"
                color="dark"
              >
                <b>
                  {{ nombreEstablecimientoLote }}
                </b>
              </ion-text>
            </ion-col>
          </ion-row>
        </ion-item>

        <div class="margen8px">
          <ion-grid fixed>
            <ion-row class="ion-align-items-center">
              <ion-col size="3" style="margin-top:4px" id="colCampania">
                <ion-text class="ion-float-right">Campaña:</ion-text>
              </ion-col>
              <ion-col>
                <ion-text
                  class="xLargeFont"
                  :color="campania.esActual ? 'primary' : 'tertiary'"
                  style="white-space: normal"
                >
                  {{ " " + nombreCampania || "" }}
                </ion-text>
              </ion-col>
              <br />
            </ion-row>
            <ion-row class="ion-align-items-center">
              <ion-col size="3" style="margin-top:4px" id="colCultivo">
                <ion-text class="ion-float-right">Cultivo:</ion-text>
              </ion-col>
              <ion-col>
                <ion-text
                  class="xLargeFont"
                  color="primary"
                  style="white-space: normal"
                >
                  {{ " " + cultivoVariedadCampania || "" }}
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <ion-card v-if="isEditable">
          <ion-row class="ion-align-items-center ion-margin-top">
            <ion-col style="margin-left:27px">
              <ion-text>
                {{ editing ? "Editar Actividad" : "Crear Actividad" }}
              </ion-text>
            </ion-col>
            <ion-col size="auto" style="margin-right:16px; padding:0px;">
              <ion-row class="ion-justify-content-end">
                <ion-col size="auto">
                  <ion-row class="ion-justify-content-center">
                    <ion-thumbnail v-if="tipoActual === 'Cosecha'">
                      <ion-img
                        src="assets/icon/actividades/icoCosecha.png"
                      ></ion-img>
                    </ion-thumbnail>
                    <ion-thumbnail v-if="tipoActual === 'Siembra'">
                      <ion-img
                        src="assets/icon/actividades/icoSiembra.png"
                      ></ion-img>
                    </ion-thumbnail>
                    <ion-thumbnail v-if="tipoActual === 'Fertilización'">
                      <ion-img
                        src="assets/icon/actividades/icoFertiliza.png"
                      ></ion-img>
                    </ion-thumbnail>
                    <ion-thumbnail v-if="tipoActual === 'Pulverización'">
                      <ion-img
                        src="assets/icon/actividades/icoPulveriza.png"
                      ></ion-img>
                    </ion-thumbnail>
                    <ion-thumbnail v-if="tipoActual === 'Riego'">
                      <ion-img
                        src="assets/icon/actividades/icoRiego.png"
                      ></ion-img>
                    </ion-thumbnail>
                    <ion-thumbnail v-if="tipoActual === 'Otra'">
                      <ion-img
                        src="assets/icon/fichaLote/icoActividad.png"
                      ></ion-img>
                    </ion-thumbnail>
                  </ion-row>
                  <ion-row class="ion-justify-content-center">
                    <ion-text
                      v-if="tipoActual === 'Cosecha'"
                      style="font-size:x-small"
                    >
                      COSECHA
                    </ion-text>
                    <ion-text
                      v-if="tipoActual === 'Siembra'"
                      style="font-size:x-small"
                    >
                      SIEMBRA
                    </ion-text>
                    <ion-text
                      v-if="tipoActual === 'Fertilización'"
                      style="font-size:x-small"
                    >
                      FERTILIZACIÓN
                    </ion-text>
                    <ion-text
                      v-if="tipoActual === 'Pulverización'"
                      style="font-size:x-small"
                    >
                      PULVERIZACIÓN
                    </ion-text>
                    <ion-text
                      v-if="tipoActual === 'Riego'"
                      style="font-size:x-small"
                    >
                      RIEGO
                    </ion-text>
                    <ion-text
                      v-if="tipoActual === 'Otra'"
                      style="font-size:x-small"
                    >
                      OTRA
                    </ion-text>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-card-content v-show="selectedActividad.tipo">
            <div v-if="isNotFertilizacion && tipoActual === 'Siembra'">
              <SiembraForm
                :error="error"
                :campaniaCultivo="campaniaCultivo"
                :actividad="selectedActividad"
                :personaActual="persona"
                @submit="submitActividad"
                @delete="deleteActividad"
                @cancel="volver"
              ></SiembraForm>
            </div>
            <div v-if="isNotFertilizacion && tipoActual === 'Cosecha'">
              <ActividadForm
                :error="error"
                :campaniaCultivo="campaniaCultivo"
                :actividad="selectedActividad"
                :tipo="selectedActividad.tipo"
                :personaActual="persona"
                @submit="submitActividad"
                @delete="deleteActividad"
                @cancel="volver"
              ></ActividadForm>
            </div>
            <div v-if="isNotFertilizacion && tipoActual === 'Pulverización'">
              <PulverizacionForm
                :error="error"
                :campaniaCultivo="campaniaCultivo"
                :actividad="selectedActividad"
                :personaActual="persona"
                @submit="submitActividad"
                @delete="deleteActividad"
                @cancel="volver"
              ></PulverizacionForm>
            </div>
            <div v-if="isNotFertilizacion && tipoActual === 'Riego'">
              <RiegoForm
                :error="error"
                :campaniaCultivo="campaniaCultivo"
                :actividad="selectedActividad"
                :personaActual="persona"
                @submit="submitActividad"
                @delete="deleteActividad"
                @cancel="volver"
              ></RiegoForm>
            </div>
            <div v-if="isNotFertilizacion && tipoActual === 'Otra'">
              <OtraActividadForm
                :error="error"
                :campaniaCultivo="campaniaCultivo"
                :actividad="selectedActividad"
                :personaActual="persona"
                @submit="submitActividad"
                @delete="deleteActividad"
                @cancel="volver"
              ></OtraActividadForm>
            </div>
            <div v-if="!isNotFertilizacion">
              <FertilizacionForm
                :error="error"
                :campaniaCultivo="campaniaCultivo"
                :actividad="selectedActividad"
                :personaActual="persona"
                @submit="submitActividad"
                @delete="deleteActividad"
                @cancel="volver"
              ></FertilizacionForm>
            </div>
          </ion-card-content>
        </ion-card>

        <ion-button
          v-if="tipoActual === ''"
          @click="volver"
          class="ion-margin-top ion-margin-bottom ion-float-end margen9pxLados"
          color="primary"
        >
          Volver
        </ion-button>

        <br />
        <br />
        <br />
        <br />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  IonButton,
  IonContent,
  IonPage,
  IonCol,
  IonImg,
  IonRow,
  IonCard,
  IonCardContent,
  IonItem,
  IonGrid,
  IonThumbnail,
  IonText,
  alertController
} from "@ionic/vue";
import http from "@/api/http";
import ActividadForm from "../../components/ActividadForm.vue";
import FertilizacionForm from "../../components/FertilizacionForm.vue";
import SiembraForm from "../../components/SiembraForm.vue";
import PulverizacionForm from "../../components/PulverizacionForm.vue";
import RiegoForm from "../../components/RiegoForm.vue";
import OtraActividadForm from "../../components/OtraActividadForm.vue";
import {
  CampaniaCultivos,
  Lotes,
  Actividades,
  Persona,
  Establecimientos
} from "@/api";
import { addOutline, pencilOutline } from "ionicons/icons";

export default {
  components: {
    IonButton,
    IonContent,
    IonGrid,
    IonImg,
    IonThumbnail,
    IonItem,
    IonRow,
    IonCol,
    IonText,
    IonCard,
    IonCardContent,
    IonPage,
    ActividadForm,
    FertilizacionForm,
    SiembraForm,
    PulverizacionForm,
    RiegoForm,
    OtraActividadForm
  },
  data: () => ({
    success: null,
    campaniaCultivo: {},
    campania: {},
    modalCreate: false,
    cultivo: {},
    productor: {},
    error: "",
    semillero: {},
    establecimiento: {},
    variedadCultivo: {},
    selectedActividad: { tipo: "" },
    lote: {},
    nombreLoteCiudad: "",
    hectareasLote: "",
    nombreEstablecimientoLote: "",
    nombreCampania: "",
    cultivoVariedadCampania: "",
    icons: {
      add: addOutline,
      edit: pencilOutline
    },
    tipoActual: "",
    tiposActividades: [
      "Siembra",
      "Fertilización",
      "Pulverización",
      "Cosecha",
      "Riego"
    ]
  }),

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"]),
    editing() {
      return !!this.$route.params.idActividad;
    },
    isNotFertilizacion() {
      return this.tipoActual !== "Fertilización";
    },
    idProductor() {
      return this.$route.params.idProductor;
    },
    persona() {
      if (this.isAdmin && this.idProductor) {
        if (this.productor && this.productor._id) {
          return this.productor;
        }
        return {};
      }
      return this.user;
    },
    txtVariedad() {
      return this.variedadCultivo ? this.variedadCultivo.variedad : "Otra";
    },
    txtSemillero() {
      return this.semillero ? this.semillero.nombre : "Otro";
    },
    isEditable() {
      return this.campaniaCultivo &&
        (this.campaniaCultivo.abierto === true ||
          this.campaniaCultivo.abierto === false)
        ? this.campaniaCultivo.abierto
        : true;
    }
  },

  watch: {
    tipoActual(val) {
      this.selectedActividad.tipo = val;
    },

    "$route.params.tipoActividad": {
      handler: function(val) {
        if (!this.editing) {
          this.selectedActividad.tipo = val;
          this.tipoActual = val;
        }
      },
      deep: true,
      immediate: true
    },

    async editing(val) {
      if (val) {
        await this.dataSync();
      }
    },

    async idProductor() {
      await this.dataSync();
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
  },

  methods: {
    ...mapActions(["getMe"]),
    volver() {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-productor-lote",
          params: {
            idProductor: this.idProductor,
            tab: "Ac",
            idLote: this.lote._id
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      } else {
        this.$router.push({
          name: "lote",
          params: {
            tab: "Ac",
            idLote: this.lote._id
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      }
      //this.$router.back();
    },
    async dataSync() {
      if (this.idProductor && this.isAdmin) {
        this.productor = await this.getProductor(this.idProductor);
      }
      if (!this.editing) {
        this.tipoActual = this.$route.params.tipoActividad;
      }
      if (this.editing) {
        this.selectedActividad = await this.getSelectedActividad();
        this.tipoActual = this.selectedActividad.tipo;
      }
      this.lote = await this.getLote();

      const campaniascultivos = await this.getCampaniaCultivo();
      if (this.$route.query.campCult) {
        const campCultElegida = campaniascultivos.find(
          cc => cc._id === this.$route.query.campCult
        );
        this.campaniaCultivo = campCultElegida
          ? campCultElegida
          : campaniascultivos[0];
      } else {
        const campCultFav = campaniascultivos.find(
          cc => cc.favorito && cc.favorito === true
        );
        this.campaniaCultivo = campCultFav ? campCultFav : campaniascultivos[0];
      }

      this.campania = this.campaniaCultivo.campania || "";
      this.cultivo = this.campaniaCultivo.cultivo || "";
      this.semillero = this.campaniaCultivo.semillero || "";
      this.variedadCultivo = this.campaniaCultivo.variedadCultivo || "";

      this.nombreLoteCiudad =
        this.lote.nombre + " - " + this.lote.localidad || "";
      this.hectareasLote = this.lote.hectareas || "";
      this.nombreCampania = this.campania.nombre || "";
      this.cultivoVariedadCampania =
        this.cultivo.nombre + " ( " + this.txtVariedad + " )" || "";
      if (this.lote.establecimiento) {
        this.establecimiento = await this.getEstablecimiento();
      } else {
        this.establecimiento = { id: "0", nombre: "-" };
      }
      this.nombreEstablecimientoLote = this.establecimiento.nombre;
    },

    async getCampaniaCultivo() {
      const { data, error } = await CampaniaCultivos.get({
        query: this.editing
          ? { _id: this.selectedActividad.campaniaCultivo }
          : { lote: this.lote._id },
        populate: "campania, cultivo, semillero, variedadCultivo",
        sort: { createdAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo campaña - cultivo",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getProductor(idProductor) {
      const { data, error } = await Persona.getBy_id(idProductor);
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Productor.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getSelectedActividad() {
      const { data, error } = await Actividades.getBy_id(
        this.$route.params.idActividad,
        {
          populate: JSON.stringify([
            { path: "productosFertilizacion" },
            { path: "productosFertilizacion", populate: "producto" },
            { path: "productosPulverizacion" },
            { path: "productosPulverizacion.producto" },
            { path: "plagasPulverizacion" },
            {
              path: "plagasPulverizacion",
              populate: [
                "maleza",
                "insecto",
                "enfermedad",
                "productosPlagas",
                "productosPlagas.producto"
              ]
            }
          ])
        }
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message:
            "Error obteniendo Actividad nro" + this.$route.params.idActividad,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getLote() {
      const { data, error } = await Lotes.getBy_id(this.$route.params.idLote);
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo lote nro" + this.$route.params.idLote,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async deleteActividad() {
      const alert = await alertController.create({
        header: "Actividad",
        message: "¿Desea eliminar esta actividad?",
        buttons: [
          {
            text: "Si",
            handler: async () => {
              const { error } = await Actividades.deleteReal(
                this.selectedActividad
              );
              if (!error) {
                const alert = await alertController.create({
                  header: "Actividad",
                  message: "Actividad Eliminada",
                  buttons: [
                    {
                      text: "Aceptar",
                      handler: () => {
                        this.volver();
                        /*                         this.$router.push({
                          name: "lote",
                          params: {
                            idLote: this.lote._id
                          }
                        }); */
                      }
                    }
                  ]
                });
                alert.present();
              }
            }
          },
          {
            text: "No"
          }
        ]
      });
      alert.present();
    },

    async getEstablecimiento() {
      const { data, error } = await Establecimientos.getBy_id(
        this.lote.establecimiento
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo establecimiento",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    goToCreateFertilizacion(fecha) {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-productor-createActividad",
          params: {
            idProductor: this.idProductor,
            idLote: this.campaniaCultivo.lote,
            tipoActividad: "Fertilización",
            fecha: fecha
          }
        });
      } else {
        this.$router.push({
          name: "createActividad",
          params: {
            idLote: this.campaniaCultivo.lote,
            tipoActividad: "Fertilización",
            fecha: fecha
          }
        });
      }
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },

    async submitActividad({
      actividades,
      rinde,
      realizaFertilizacionLuegoSiembra
    }) {
      this.error = "";
      const { data, error } = await http.post(
        "/api/save-actividades",
        actividades
      );
      if (error) {
        this.error = error;
      } else {
        this.success = true;
        if (rinde) {
          const { error2 } = await http.post("/api/save-rinde", {
            params: {
              actividades: data.map(a => {
                return {
                  _id: a._id,
                  campaniaCultivo: a.campaniaCultivo ? a.campaniaCultivo : ""
                };
              }),
              rinde: rinde
            }
          });
          if (error2) {
            this.error = error2;
          }
        }
        const alert = await alertController.create({
          header: "Actividad",
          message:
            realizaFertilizacionLuegoSiembra &&
            realizaFertilizacionLuegoSiembra === "Si"
              ? "A continuación se solicitarán los datos correspondientes a la fertilización relacionada en otro formulario"
              : "Actividad Guardada",
          buttons: [
            {
              text: "Aceptar",
              handler: () => {
                this.selectedActividad = { tipo: "" };
                this.tipoActual = "";
                if (
                  realizaFertilizacionLuegoSiembra &&
                  realizaFertilizacionLuegoSiembra === "Si"
                ) {
                  this.goToCreateFertilizacion(actividades[0].fecha);
                } else {
                  this.volver();
                }

                /*                 this.$router.push({
                  name: "lote",
                  params: { idLote: this.lote._id }
                }); */
              }
            }
          ]
        });
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  background: transparent !important;
}

ion-modal {
  --ion-background-color: #ffffff85;
}

.negrita {
  font-size: 175%;
  margin-left: 0px;
  font-weight: bold;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

.margen4px {
  margin-left: 4px;
}

.margen8px {
  margin-left: 8px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  float: right;
}

.width25 {
  width: 25%;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

.margen8pxArriba {
  margin-top: 8px;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.itemColor {
  --background: rgba(var(--ion-color-primary-rgb), 0.6);
  --border-color: rgba(var(--ion-color-primary-rgb), 0.6);
}

@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
}
</style>
