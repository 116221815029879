<template>
  <ion-content :scroll-events="true">
    <ion-card>
      <ion-card-content>
        <ion-row>
          <ion-col>
            <ion-label position="floating">
              {{ editing ? "Editar " + plaga.tipoPlaga : "Crear " + tipoPlaga }}
            </ion-label>
          </ion-col>
        </ion-row>
          <ion-tab-bar slot="top" :selected-tab="tipoPlaga">
            <ion-tab-button @click="tipoPlaga = 'Maleza'" tab="Maleza">
              <ion-img
                src="assets/icon/plagas/icoPlgMaleza.png"
                style="height:70%"
              />
              <ion-label style="font-weight: bold">Maleza</ion-label>
            </ion-tab-button>
            <ion-tab-button @click="tipoPlaga = 'Insecto'" tab="Insecto">
              <ion-img
                src="assets/icon/plagas/icoPlgInsecto.png"
                style="height:70%"
              />
              <ion-label style="font-weight: bold">Insecto</ion-label>
            </ion-tab-button>
            <ion-tab-button @click="tipoPlaga = 'Enfermedad'" tab="Enfermedad">
              <ion-img
                src="assets/icon/plagas/icoPlgEnferm.png"
                style="height:70%"
              />
              <ion-label style="font-weight: bold">Enfermedad</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
        <ion-item v-if="tipoPlaga">
          <ion-label position="floating">
            {{ editing ? plaga.tipoPlaga : tipoPlaga }}
          </ion-label>
          <ion-select
            :value="plagaDetail"
            @ionChange="plagaDetail = $event.target.value"
            :placeholder="plagaDetail ? plagaDetail.nombre : undefined"
          >
            <ion-select-option v-for="pl in plagas" :value="pl" :key="pl.id">
              {{ pl.nombre }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-label style="margin-left:15px">
          Dificultad
        </ion-label>
        <ion-tab-bar slot="top" :selected-tab="dificultad">
          <ion-tab-button
            @click="dificultad = 'Facil'"
            tab="Facil"
            class="greenButton"
          >
            <img src="assets/icon/plagas/icoPlgFacil.png" style="height:70%" />
            <ion-text style="font-weight: bold">Fácil</ion-text>
          </ion-tab-button>
          <ion-tab-button
            @click="dificultad = 'Medio'"
            tab="Medio"
            class="yellowButton"
          >
            <img src="assets/icon/plagas/icoPlgMedio.png" style="height:70%" />
            <ion-text style="font-weight: bold">Moderada</ion-text>
          </ion-tab-button>
          <ion-tab-button
            @click="dificultad = 'Dificil'"
            tab="Dificil"
            class="redButton"
          >
            <img
              src="assets/icon/plagas/icoPlgDificil.png"
              style="height:70%"
            />
            <ion-text style="font-weight: bold">Dificil</ion-text>
          </ion-tab-button>
        </ion-tab-bar>
        <ion-list-header>
          <ion-text>Productos:</ion-text>
        </ion-list-header>
        <ion-list v-for="pr in productosSeleccionables" :value="pr" :key="pr._id">
          <ion-item
            class="itemBorder"
          >
            <ion-grid fixed>
              <ion-row>
                <ion-col size="auto">
                  <Ion-checkbox :checked="estaSeleccionado(pr)" @click="addProducto(pr)"></Ion-checkbox>
                </ion-col>
                <ion-col size="auto">
                  <ion-thumbnail>
                    <ion-img src="assets/icon/plagas/icoPlgProducto.png" />
                  </ion-thumbnail>
                </ion-col>
                <ion-col style="white-space: normal">
                  {{ pr.producto.nombre }}
                </ion-col>
                <ion-col
                  size="auto"
                  v-if="pr.dosis && pr.unidad"
                  style="white-space: normal"
                >
                  {{ pr.dosis + " " + pr.unidad }}
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-list>
        <ion-button
          fill="clear"
          size="large"
          color="danger"
          @click="$emit('delete')"
          v-if="editing"
        >
          <ion-icon :md="icons.trash" :ios="icons.trash"></ion-icon>
        </ion-button>
        <ion-row class="ion-float-right">
          <ion-col size="auto">
            <ion-button
              fill="outline"
              class="ion-margin-top ion-float-end"
              color="medium"
              @click="cancel"
            >
              Cancelar
            </ion-button>
          </ion-col>
          <ion-col size="auto">
            <ion-button
              class="ion-float-right ion-margin-top ion-margin-bottom"
              @click="submitEditOrCreate()"
            >
              Aceptar
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonThumbnail,
  IonImg,
  IonItem,
  IonSelect,
  IonSelectOption,
  alertController,
  IonTabBar,
  IonTabButton,
  IonList,
  IonListHeader,
  IonText,
  IonIcon,
  IonButton,
  IonCheckbox
} from "@ionic/vue";
import { trashOutline, addOutline } from "ionicons/icons";
import { Malezas, Insectos, Enfermedades } from "@/api";

export default {
  components: {
    IonContent,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonThumbnail,
    IonImg,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonTabBar,
    IonTabButton,
    IonList,
    IonListHeader,
    IonText,
    IonIcon,
    IonButton,
    IonCheckbox
},

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    plaga: { type: Object, default: () => ({}) },
    productos: { type: Array, default: () => ([]) },
  },

  data: () => ({
    plagaDetail: null,
    tipoPlaga: "",
    plagas: [],
    dificultad: "",
    selectedProducto: {},
    productosElegidos: [],
    productosSeleccionables: [],
    modalEditProducto: false,
    icons: {
      trash: trashOutline,
      add: addOutline
    }
  }),

  computed: {
    editing() {
      if (!this.plaga._id) {
        // un campo obligatorio para saber si ya creo la plaga pero no la guardó aún
        if (this.plaga.dificultad) {
          return true;
        }
        return false;
      }
      return true;
    },
    plagaActual() {
      if (this.editing) {
        if (this.plaga.tipoPlaga === "Maleza") {
          return this.plaga.maleza;
        }
        if (this.plaga.tipoPlaga === "Insecto") {
          return this.plaga.insecto;
        }
        if (this.plaga.tipoPlaga === "Enfermedad") {
          return this.plaga.enfermedad;
        }
      }
      return {};
    }
  },

  async mounted() {
    await this.dataSync();
  },
  watch: {
    async plaga() {
      await this.dataSync();
    },
    async tipoPlaga(val){
      if (val === "Maleza") {
        this.plagas = await this.getMalezas();
      }
      if (val === "Insecto") {
        this.plagas = await this.getInsectos();
      }
      if (val === "Enfermedad") {
        this.plagas = await this.getEnfermedades();
      }
    }
  },

  methods: {
    seleccionado(pr) {
      if(pr._id){
        const prodsMap = this.productosElegidos.map(p => {return {producto: p.producto, 
            dosis: p.dosis, 
            unidad: p.unidad}});
        return prodsMap.find(p => p.producto._id === pr.producto._id);
      }
      return this.productosElegidos.find(p => p.producto._id === pr.producto._id);
    },

    estaSeleccionado(pr){
      return this.seleccionado(pr) !== undefined;
    },

    cleaner() {
      this.dificultad = "";
      this.tipoPlaga = "";
      this.plagaDetail = null;
      this.productosElegidos = [];
    },

    goToEditProducto(prod, index) {
      if (!this.plagaDetail) {
        return;
      }
      this.selectedProducto = { ...prod, index };
      this.modalEditProducto = true;
    },

    goToCreateProducto() {
      if (!this.plagaDetail) {
        return;
      }
      this.selectedProducto = {};
      this.modalEditProducto = true;
    },

    cancelProducto() {
      this.modalEditProducto = false;
      this.selectedProducto = {};
    },

    async dataSync() {        
        this.tipoPlaga = this.plaga.tipoPlaga;
        this.dificultad = this.plaga.dificultad;
        this.plagaDetail = this.plagaActual;
        if (this.plaga.productosPlagas && this.plaga.productosPlagas.length) {
          this.productosElegidos = this.plaga.productosPlagas;
        }
        this.productosSeleccionables = this.productos.length && this.productos.length > 0 ? 
          this.productos : 
          this.productosElegidos;
      
    },

    async getMalezas() {
      const { data, error } = await Malezas.get({
        sort: { nombreLower: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Malezas.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getInsectos() {
      const { data, error } = await Insectos.get({
        sort: { nombreLower: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Insectos.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getEnfermedades() {
      const { data, error } = await Enfermedades.get({
        sort: { nombreLower: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Enfermedades.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async deleteProducto() {
      const alert = await alertController.create({
        header: "Plaga",
        translucent: true,
        message: "¿Desea eliminar este Producto?",
        buttons: [
          {
            text: "Si",
            handler: async () => {
              this.productosElegidos = this.productos.filter(
                p => p._id !== this.selectedProducto._id
              );
              this.modalEditProducto = false;
              this.selectedProducto = {};
            }
          },
          {
            text: "No"
          }
        ]
      });
      alert.present();
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    addProducto( productoPlaga ) {
      const existsProd = this.productosElegidos.find(p => p.producto._id === productoPlaga.producto._id &&
          p.dosis === productoPlaga.dosis &&
          p.unidad === productoPlaga.unidad);
      if(!existsProd){
        this.productosElegidos.push(productoPlaga);
      }
    },

    isEmpty(obj){
      return JSON.stringify(obj) === '{}';
    },

    async submitEditOrCreate() {
      let error2 = "";
      if (!this.plagaDetail || 
            this.plagaDetail === null || 
            this.isEmpty(this.plagaDetail)) 
      {
        error2 += "<li>Elija una <b>Plaga</b>." + `<br/></li>`;
      }
      if (!this.tipoPlaga) {
        error2 += "<li>Elija una <b>Tipo de Plaga</b>." + `<br/></li>`;
      }
      if (!this.dificultad) {
        error2 += "<li>Elija una <b>Dificultad</b>." + `<br/></li>`;
      }
      if (!this.productosElegidos || !this.productosElegidos.length || this.productosElegidos.length === 0) {
        error2 += "<li>Elija al menos un <b>Producto</b>." + `<br/></li>`;
      }
      if (!error2) {
        this.$emit("submit", {
          plaga: {
            _id: this.plaga._id,
            tipoPlaga: this.tipoPlaga,
            dificultad: this.dificultad,
            maleza: this.tipoPlaga === "Maleza" ? this.plagaDetail : undefined,
            insecto:
              this.tipoPlaga === "Insecto" ? this.plagaDetail : undefined,
            enfermedad:
              this.tipoPlaga === "Enfermedad" ? this.plagaDetail : undefined,
            productosPlagas: this.productosElegidos,
            editing: this.editing
          }
        });
      } else {
        const alert = await alertController.create({
          header: "Error al crear la Plaga",
          message: error2,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
ion-tab-button {
  --ripple-color: var(--ion-color-primary);
  --color: var(--ion-color-dark);
  --color-selected: var(--ion-color-primary);
}

ion-tab-button.tab-selected {
  --background: rgba(var(--ion-color-dark-rgb), 0.75) !important;
  --ripple-color: var(--ion-color-light);
  --color-selected: var(--ion-color-light);
}

.redButton {
  --background: rgba(255, 0, 0, 0.12);
  --color-selected: rgba(255, 0, 0, 1);
}
.yellowButton {
  --background: rgba(242, 255, 0, 0.2);
  --color-selected: rgba(150, 162, 0, 1);
}
.greenButton {
  --background: rgba(0, 231, 12, 0.2);
  --color-selected: rgba(0, 231, 12, 1);
}

ion-tab-bar {
  justify-content: center;
}

ion-item {
  --pading-start: 8px;
}

ion-select {
  --placeholder-opacity: 1 !important;
  --placeholder-color: var(--ion-color-dark);
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

ion-content {
  --background: transparent;
}
</style>
