<template>
  <v-form @submit="submitEditOrCreate">
    <ion-item>
      <ion-label position="floating">Fecha</ion-label>
      <ion-datetime v-model="fecha" :value="fecha" display-timezone="Etc/GMT+3" display-format="DD/MM/YY"
        picker-format="DD/MM/YY" cancelText="Cancelar" doneText="Aceptar"></ion-datetime>
    </ion-item>
    <div v-if="false">
      <div v-if="!displayTiposPlagas" style="margin-top:16px; margin-left: 15px;">
        <ion-text>
          <b>
            {{ tipoPlaga }}
          </b>
        </ion-text>
      </div>
      <ion-tabs v-if="displayTiposPlagas">
        <ion-tab-bar slot="top" :selected-tab="tipoPlaga">
          <ion-tab-button @click="tipoPlaga = 'Maleza'" tab="Maleza">
            <ion-img src="assets/icon/plagas/icoPlgMaleza.png" style="height:70%" />
            <ion-label>Maleza</ion-label>
          </ion-tab-button>
          <ion-tab-button @click="tipoPlaga = 'Insecto'" tab="Insecto">
            <ion-img src="assets/icon/plagas/icoPlgInsecto.png" style="height:70%" />
            <ion-label>Insecto</ion-label>
          </ion-tab-button>
          <ion-tab-button @click="tipoPlaga = 'Enfermedad'" tab="Enfermedad">
            <ion-img src="assets/icon/plagas/icoPlgEnferm.png" style="height:70%" />
            <ion-label>Enfermedad</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
      <div class="ion-margin-top" v-if="tipoPlaga === 'Maleza'">
        <ion-label style="margin-left: 8px">Aplicación Selectiva?</ion-label>
        <ion-radio-group v-model="aplicacionSelectiva">
          <ion-grid fixed>
            <ion-row class="ion-align-items-center">
              <ion-col size="auto">
                <ion-label style="margin-right: 8px">Si</ion-label>
                <ion-radio @click="aplicacionSelectiva = 'Si'" value="Si"></ion-radio>
              </ion-col>
              <ion-col size="auto">
                <ion-label style="margin-right: 8px">No</ion-label>
                <ion-radio @click="aplicacionSelectiva = 'No'" value="No"></ion-radio>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-radio-group>
        <ion-item v-if="aplicacionSelectiva === 'Si'">
          <ion-label position="floating">
            Tipo
          </ion-label>
          <ion-select :value="tipoAplicacion" @ionChange="tipoAplicacion = $event.target.value">
            <ion-select-option v-for="t in tiposAplicaciones" :value="t" :key="t">
              {{ t }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>
    </div>
    <div>
      <ion-list-header>
        <ion-text>Productos:</ion-text>
        <ion-fab-button fill="solid" color="primary" size="small" @click="goToCreateProducto">
          <ion-icon :md="icons.add" :ios="icons.add" color="light"></ion-icon>
        </ion-fab-button>
      </ion-list-header>
      <ion-list v-for="(pr) in productos" :value="pr" :key="pr._id">
        <ion-item @click="goToEditProducto(pr)" button="true" type="button" class="itemBorder">
          <ion-grid fixed>
            <ion-row class="">
              <ion-col size="auto">
                <ion-thumbnail>
                  <ion-img src="assets/icon/plagas/icoPlgProducto.png" />
                </ion-thumbnail>
              </ion-col>
              <ion-col style="white-space: normal">
                {{ pr.producto.nombre }}
              </ion-col>
              <ion-col size="auto" v-if="pr.dosis && pr.unidad" style="white-space: normal">
                {{ pr.dosis + " " + pr.unidad }}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-list>
      <ion-item v-if="eiqTotal && eiqTotal !== 0">
        <ion-label>{{ `EIQ Total: ${eiqTotal}` }}</ion-label>
      </ion-item>
      <ion-list-header class="ion-margin-top">
        <ion-text>Plagas:</ion-text>
        <ion-fab-button fill="solid" color="primary" size="small" @click="createPlagaModal"
          :disabled="!productos || !productos.length || productos.length === 0">
          <ion-icon :md="icons.add" :ios="icons.add" color="light"></ion-icon>
        </ion-fab-button>
      </ion-list-header>
      <ion-list v-for="(p, index) in plagas" :value="p" :key="p._id">
        <ion-item @click="goToEditPlaga(p, index)" button="true" type="button" class="itemBorder">
          <ion-grid fixed>
            <ion-row>
              <ion-col size="auto">
                <ion-thumbnail>
                  <ion-img src="assets/icon/plagas/icoPlgMaleza.png" style="height:70%" v-if="p.tipoPlaga === 'Maleza'" />
                  <ion-img src="assets/icon/plagas/icoPlgInsecto.png" v-if="p.tipoPlaga === 'Insecto'" />
                  <ion-img src="assets/icon/plagas/icoPlgEnferm.png" v-if="p.tipoPlaga === 'Enfermedad'" />
                </ion-thumbnail>
              </ion-col>
              <ion-col>
                <ion-row>
                  <b>
                    {{ nombrePlaga(p) }}
                  </b>
                </ion-row>
                <ion-row style="font-size:75%">
                  {{ nombresProductoPlaga(p) }}
                </ion-row>
              </ion-col>
              <ion-col size="auto" class="ion-float-end">
                <ion-img src="assets/icon/plagas/icoPlgFacil.png" style="height:50%" v-if="p.dificultad === 'Facil'" />
                <ion-img src="assets/icon/plagas/icoPlgMedio.png" style="height:50%" v-if="p.dificultad === 'Medio'" />
                <ion-img src="assets/icon/plagas/icoPlgDificil.png" style="height:50%"
                  v-if="p.dificultad === 'Dificil'" />
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-list>
    </div>
    <ion-item :style="!plagas.length ? 'margin-top: 24px' : ''">
      <ion-label position="floating">Momento</ion-label>
      <ion-select :value="momento" @ionChange="momento = $event.target.value">
        <ion-select-option v-for="m in momentos" :value="m" :key="m">
          {{ m }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-button fill="clear" size="large" color="danger" @click="$emit('delete')" v-if="editing">
      <ion-icon :md="icons.trash" :ios="icons.trash"></ion-icon>
    </ion-button>
    <ion-row class="ion-float-right">
      <ion-col size="auto">
        <ion-button fill="outline" class="ion-margin-top ion-float-end" color="medium" @click="cancel">
          Cancelar
        </ion-button>
      </ion-col>
      <ion-col size="auto">
        <ion-button class="ion-float-right ion-margin-top ion-margin-bottom" @click="submitEditOrCreate">
          Aceptar
        </ion-button>
      </ion-col>
    </ion-row>
  </v-form>

  <ion-modal :is-open="modalEditPlaga" @didDismiss="modalEditPlaga = false" :backdropDismiss="false">
    <PlagaForm :error="error" :plaga="selectedPlaga" :tipoPlaga="tipoPlaga" :productos="productos" @cancel="cancelPlaga"
      @submit="addPlaga" @delete="deletePlaga" />
  </ion-modal>

  <ion-modal :is-open="modalLotes" @didDismiss="modalLotes = false" :backdropDismiss="false">
    <ion-content :scroll-events="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title>Otros lotes a aplicar Actividad</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list v-for="l in lotes" :value="l" :key="l._id">
            <ion-item button="false" class="itemBorder">
              <ion-checkbox @ionChange="agregarLote(l._id)"></ion-checkbox>
              <ion-label class="ion-text-capitalize" style="margin-left: 8px; white-space: normal;">
                {{ l.establecimiento + " - " + l.lote + " (" + l.cultivo + " - " + l.variedad + ")" }}
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-row class="ion-float-right">
            <ion-col size="auto">
              <ion-button fill="outline" class="ion-margin-top ion-float-end" color="medium" @click="cancelLotesModal">
                Cancelar
              </ion-button>
            </ion-col>
            <ion-col size="auto">
              <ion-button class="ion-float-right ion-margin-top ion-margin-bottom" @click="aceptLotesModal">
                Aceptar
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-modal>

  <ion-modal :is-open="modalEditProducto" @didDismiss="modalEditProducto = false" :backdropDismiss="false">
    <ion-content :scroll-events="true">
      <ion-card>
        <ion-card-content>
          <ProductoPlagaForm :error="error" :productoPlaga="selectedProducto" @cancel="cancelProducto"
            @submit="addProducto" @delete="deleteProducto" />
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-modal>
</template>

<script>
import * as V from "vee-validate/dist/vee-validate";
import {
  IonDatetime,
  //IonInput,
  IonLabel,
  alertController,
  IonList,
  IonListHeader,
  IonFabButton,
  IonText,
  IonGrid,
  IonModal,
  IonRadioGroup,
  IonRadio,
  IonSelect,
  IonSelectOption,
  IonTabButton,
  IonTabBar,
  IonCheckbox,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  //IonCardHeader,
  IonRow,
  IonCol,
  IonThumbnail,
  IonImg,
  IonIcon,
  IonItem,
  //IonCardTitle,
  //IonRow,
  //IonGrid,
  IonButton
} from "@ionic/vue";
import { mapState, mapActions } from "vuex";
import { trashOutline, addOutline } from "ionicons/icons";
import PlagaForm from "./PlagaForm.vue";
import ProductoPlagaForm from "./ProductoPlagaForm.vue";
import http from "@/api/http";
import { PlagasPulverizacion, Lotes, CampaniaCultivos } from "@/api";

export default {
  components: {
    IonDatetime,
    //IonInput,
    IonText,
    IonCheckbox,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    //IonCardHeader,
    IonLabel,
    IonFabButton,
    IonList,
    IonRadioGroup,
    IonRadio,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonTabButton,
    IonTabBar,
    IonListHeader,
    IonItem,
    //IonCardTitle,
    IonRow,
    IonCol,
    IonGrid,
    IonModal,
    IonButton,
    IonImg,
    IonIcon,
    VForm: V.Form,
    PlagaForm,
    ProductoPlagaForm
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    campaniaCultivo: { type: Object, default: () => ({}) },
    actividad: { type: Object, default: () => ({}) },
    personaActual: { type: Object, default: () => ({}) }
  },

  data: () => ({
    reset: false,
    fecha: "",
    momento: "",
    momentos: [
      "Barbecho largo",
      "Barbecho corto",
      "Presiembra",
      "Siembra",
      "Preemergencia",
      "Vegetativo temprano",
      "Vegetativo tardío",
      "Reproductivo temprano",
      "Reproductivo tardío",
      "Desecante cosecha"
    ],
    tipoPlaga: "",
    selectedPlaga: {},
    aplicacionSelectiva: "",
    tipoAplicacion: "",
    tiposAplicaciones: ["WEED IT", "WEEDSEEKER"],
    deleteAct: false,
    modalEditPlaga: false,
    plagas: [],
    modalLotes: false,
    plagasAux: {},
    prodsAux: {},
    lotes: [],
    lotesElegidos: [],
    selectedProducto: {},
    productos: [],
    eiqTotal: 0,
    modalEditProducto: false,
    confirmarSubmit: false,
    icons: {
      trash: trashOutline,
      add: addOutline
    }
    //hora: ""
    //error: "",
  }),

  computed: {
    ...mapState(["user"]),
    editing() {
      return this.actividad._id;
    },
    displayTiposPlagas() {
      if (this.plagas.length > 0) {
        return false;
      }
      return true;
    }
  },

  async mounted() {
    await this.getMe();
    this.dataSync();
  },

  watch: {
    success(val) {
      if (val) {
        this.cleaner();
      }
    },
    actividad() {
      this.cleaner();
      this.dataSync();
    },
    tipoPlaga(val) {
      if (val !== "Maleza") {
        this.aplicacionSelectiva = "";
        this.tipoAplicacion = "";
      }
    }
  },

  methods: {
    ...mapActions(["getMe"]),
    cleaner() {
      this.fecha = "";
      this.momento = "";
      this.aplicacionSelectiva = "";
      this.tipoAplicacion = "";
      this.productos = [];
      this.eiqTotal = 0;
    },

    calcEiqTotal(prods) {
      if (!prods || !prods.length || prods.length === 0) return 0;
      let total = 0;
      prods.forEach(p => {
        if (p.dosis && p.dosis !== 0 && p.producto &&
          p.producto.eiq && p.producto.eiq !== 0 &&
          p.producto.porcConcentracionPA && p.producto.porcConcentracionPA !== 0) {
          total += p.dosis * p.producto.eiq * (p.producto.porcConcentracionPA / 100);
        }
      });
      return total;
    },

    nombrePlaga(plaga) {
      if (plaga.tipoPlaga === "Maleza") {
        return plaga.maleza.nombre;
      }
      if (plaga.tipoPlaga === "Insecto") {
        return plaga.insecto.nombre;
      }
      return plaga.enfermedad.nombre;
    },

    nombresProductoPlaga(plaga) {
      const nombres = plaga.productosPlagas.map(p => p.producto.nombre);
      let prods = nombres.join(", ");
      return prods;
    },

    goToEditPlaga(plaga, index) {
      this.selectedPlaga = { ...plaga, editingIndex: index };
      this.modalEditPlaga = true;
    },

    createPlagaModal() {
      this.selectedPlaga = {};
      this.modalEditPlaga = true;
    },

    /*     goToEditProducto(prod, index) {
          this.selectedProducto = { ...prod, index };
          this.modalEditProducto = true;
        }, */

    goToEditProducto(prod) {
      this.selectedProducto = prod;
      this.modalEditProducto = true;
    },

    goToCreateProducto() {
      this.selectedProducto = {};
      this.modalEditProducto = true;
    },

    cancelProducto() {
      this.modalEditProducto = false;
      this.selectedProducto = {};
    },

    agregarLote(id) {
      this.lotesElegidos.push(id);
    },

    async openLotesModal() {
      this.lotes = await this.getCampaniasCultivoPropias();
      this.lotesElegidos = [];
      this.modalLotes = true;
    },

    async aceptLotesModal() {
      this.modalLotes = false;
      this.lotes = [];
      const actividades = [];
      actividades.push({
        campaniaCultivo: this.campaniaCultivo,
        fecha: this.fecha,
        tipo: "Pulverización",
        tipoPlaga: this.tipoPlaga,
        momentoPulverizacion: this.momento,
        aplicacionSelectiva:
          this.tipoPlaga === "Maleza" && this.aplicacionSelectiva !== ""
            ? this.aplicacionSelectiva
            : undefined,
        tipoAplicacion:
          this.tipoPlaga === "Maleza" &&
            this.aplicacionSelectiva === "Si" &&
            this.tipoAplicacion !== ""
            ? this.tipoAplicacion
            : undefined,
        eiqTotal: this.calcEiqTotal(this.prodsAux),
        plagasPulverizacion: this.plagasAux,
        productosPulverizacion: this.prodsAux
      });
      for (let l of this.lotesElegidos) {
        /*         let dataProd = {};
                if(this.productos){
                  const { data } = await http.post("/api/save-productos-plaga", this.productos);
                  dataProd = data;
                } */
        const { data } = await http.post("/api/save-plagas", this.plagas);
        actividades.push({
          campaniaCultivo: l,
          fecha: this.fecha,
          tipo: "Pulverización",
          tipoPlaga: this.tipoPlaga,
          momentoPulverizacion: this.momento,
          aplicacionSelectiva:
            this.tipoPlaga === "Maleza" && this.aplicacionSelectiva !== ""
              ? this.aplicacionSelectiva
              : undefined,
          tipoAplicacion:
            this.tipoPlaga === "Maleza" &&
              this.aplicacionSelectiva === "Si" &&
              this.tipoAplicacion !== ""
              ? this.tipoAplicacion
              : undefined,
          eiqTotal: this.calcEiqTotal(this.productos),
          plagasPulverizacion: data,
          productosPulverizacion: this.productos
        });
      }
      this.$emit("submit", {
        actividades
      });
    },

    cancelLotesModal() {
      this.modalLotes = false;
      this.lotes = [];
      this.lotesElegidos = [];
    },

    dataSync() {
      this.plagas = this.editing ? this.actividad.plagasPulverizacion : [];
      this.productos = this.editing ? this.actividad.productosPulverizacion : [];
      this.plagasAux = {};
      this.prodsAux = {};
      if (this.editing) {
        this.fecha = new Date(this.actividad.fecha).toISOString();
        this.momento = this.actividad.momentoPulverizacion;
        this.eiqTotal = this.productos && this.productos !== [] ? this.calcEiqTotal(this.productos) : this.actividad.eiqTotal || 0;
        this.tipoPlaga = this.actividad.tipoPlaga;
        this.aplicacionSelectiva =
          this.tipoPlaga === "Maleza" ? this.actividad.aplicacionSelectiva : "";
        this.tipoAplicacion =
          this.tipoPlaga === "Maleza" &&
            this.actividad.aplicacionSelectiva === "Si"
            ? this.actividad.tipoAplicacion
            : "";
      } else {
        setTimeout(async () => {
          this.lotes = await this.getCampaniasCultivoPropias();
        }, 1500);
      }
    },

    async getCampaniasCultivoPropias() {
      const campaniaActual = this.campaniaCultivo.campania ? this.campaniaCultivo.campania : {};
      const pers = this.personaActual;
      if (!pers._id) {
        return;
      }

      const query = {
        persona: pers._id,
        deleted: false
      };

      let idsLotes = [];
      const ifTrue = true;

      if (ifTrue) {
        const { data, error } = await Lotes.get({
          query: query,
          populate: "persona",
          sort: { updatedAt: -1 }
        });
        if (error) {
          const alert = await alertController.create({
            header: "Error",
            message: "Error obteniendo lotes.",
            buttons: [
              {
                text: "Aceptar"
              }
            ]
          });
          return alert.present();
        }
        idsLotes = data
          .filter(l => l._id !== this.campaniaCultivo.lote)
          .map(l => l._id);
      }

      const { data, error } = await CampaniaCultivos.get({
        query: { deleted: false, campania: campaniaActual, lote: { $in: idsLotes } },
        populate: JSON.stringify([
          { path: "cultivo" },
          { path: "variedadCultivo" },
          { path: "lote" },
          { path: "lote", populate: "establecimiento" },
        ]),
        sort: { updatedAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo lotes.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }

      const lotes = data.map(cc => {
        return {
          _id: cc._id,
          lote: cc.lote.nombre,
          establecimiento: cc.lote.establecimiento && cc.lote.establecimiento.nombre ? cc.lote.establecimiento.nombre : "",
          cultivo: cc.cultivo && cc.cultivo.nombre ? cc.cultivo.nombre : "",
          variedad:
            cc.variedadCultivo && cc.variedadCultivo.variedad
              ? cc.variedadCultivo.variedad
              : ""
        };
      }).sort((a, b) => {
        const extractParts = (str) => {
          const match = str.match(/(\d+)|(\D+)/g);
          return match ? match.map(part => isNaN(part) ? part : parseInt(part)) : [];
        };

        const compareParts = (partsA, partsB) => {
          for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
            if (partsA[i] !== partsB[i]) {
              return isNaN(partsA[i]) || isNaN(partsB[i]) ? partsA[i].toString().localeCompare(partsB[i].toString()) : partsA[i] - partsB[i];
            }
          }
          return partsA.length - partsB.length;
        };

        const compareFields = (fieldA, fieldB) => {
          const partsA = extractParts(fieldA);
          const partsB = extractParts(fieldB);
          return compareParts(partsA, partsB);
        };

        if (a.establecimiento !== b.establecimiento) {
          return compareFields(a.establecimiento, b.establecimiento);
        }
        if (a.lote !== b.lote) {
          return compareFields(a.lote, b.lote);
        }
        if (a.cultivo !== b.cultivo) {
          return compareFields(a.cultivo, b.cultivo);
        }
        return compareFields(a.variedad, b.variedad);
      });

      return lotes;
    },

    async deletePlaga() {
      try {
        const alert = await alertController.create({
          header: "Plaga",
          message: "¿Desea eliminar esta Plaga?",
          buttons: [
            {
              text: "Si",
              handler: async () => {
                const { error } = await PlagasPulverizacion.deleteReal(
                  this.selectedPlaga
                );
                if (!error) {
                  const alert = await alertController.create({
                    header: "Plaga",
                    message: "Plaga Eliminada",
                    buttons: [
                      {
                        text: "Aceptar",
                        handler: async () => {
                          this.modalEditPlaga = false;
                          this.selectedPlaga = {};
                          window.location.reload();
                        }
                      }
                    ]
                  });
                  alert.present();
                }
              }
            },
            {
              text: "No"
            }
          ]
        });
        alert.present();
      } catch (e) {
        console.log(e);
      }
    },

    async deleteProducto() {
      const alert = await alertController.create({
        header: "Plaga",
        translucent: true,
        message: "¿Desea eliminar este Producto?",
        buttons: [
          {
            text: "Si",
            handler: async () => {
              this.productos = this.productos.filter(
                p => p._id !== this.selectedProducto._id
              );
              this.modalEditProducto = false;
              this.selectedProducto = {};
              this.eiqTotal = this.productos && this.productos !== [] ?
                this.calcEiqTotal(this.productos) : this.actividad.eiqTotal || 0;
            }
          },
          {
            text: "No"
          }
        ]
      });
      alert.present();
    },

    cancelPlaga() {
      this.modalEditPlaga = false;
      this.selectedPlaga = {};
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    async addPlaga({ plaga }) {
      if (plaga._id || plaga.editing) {
        if (plaga._id) {
          this.plagas = this.plagas.map(p => {
            if (p._id === plaga._id) {
              return plaga;
            }
            return p;
          });
        } else {
          this.plagas[plaga.editingIndex] = plaga;
        }
      } else {
        this.plagas.push(plaga);
      }
      this.modalEditPlaga = false;
      this.selectedPlaga = {};
    },


    addProducto({ productoPlaga }) {
      if (productoPlaga._id) {
        this.productos = this.productos.map(p => {
          if (p._id === productoPlaga._id) {
            return productoPlaga;
          }
          return p;
        });
      } else {
        this.productos.push(productoPlaga);
      }
      this.modalEditProducto = false;
      this.selectedProducto = {};
      this.eiqTotal = this.productos && this.productos !== [] ?
        this.calcEiqTotal(this.productos) : this.actividad.eiqTotal || 0;
    },

    async submitEditOrCreate() {
      this.$emit("update:success", false);
      /*       let dataProd = {};
            let errorProd = {};
            if(this.productos){
              const { data, error } = await http.post("/api/save-productos-plaga", this.productos);
              dataProd = data;
              errorProd = error;
            } */
      const { data, error } = await http.post("/api/save-plagas", this.plagas);
      let error2 = "";
      if (error) {
        error2 += "<li>Error al guardar la/s plaga/s</b>." + `<br/></li>`;
      }
      /*       if (errorProd) {
              error2 += "<li>Error al guardar el/los producto/s</b>." + `<br/></li>`;
            } */
      if (!this.fecha) {
        error2 += "<li>Complete el campo <b>Fecha</b>." + `<br/></li>`;
      }
      if (!this.momento || !this.momentos.includes(this.momento)) {
        error2 += "<li>Elija un <b>Momento</b>." + `<br/></li>`;
      }
      /*       if (this.tipoPlaga === "Maleza" && this.aplicacionSelectiva === "") {
              error2 +=
                "<li>Elija una opción para el campo <b>Aplicación Selectiva?</b>." +
                `<br/></li>`;
            } 
            if (
              this.tipoPlaga === "Maleza" &&
              this.aplicacionSelectiva === "Si" &&
              this.tipoAplicacion === ""
            ) {
              error2 +=
                "<li>Elija una opción para el campo <b>Tipo</b>." + `<br/></li>`;
            }*/
      if (!error2) {
        if (!this.editing && this.lotes && this.lotes.length) {
          const alert = await alertController.create({
            header: "Actividades",
            message:
              "Desea replicar esta actividad para otros lotes del establecimiento?",
            buttons: [
              {
                text: "Si",
                handler: async () => {
                  await this.openLotesModal();
                  this.plagasAux = data;
                  this.prodsAux = this.productos;
                }
              },
              {
                text: "No",
                handler: () => {
                  this.cancelLotesModal();
                  const actividades = [];
                  actividades.push({
                    campaniaCultivo: this.campaniaCultivo,
                    fecha: this.fecha,
                    tipo: "Pulverización",
                    tipoPlaga: this.tipoPlaga,
                    momentoPulverizacion: this.momento,
                    aplicacionSelectiva:
                      this.tipoPlaga === "Maleza" &&
                        this.aplicacionSelectiva !== ""
                        ? this.aplicacionSelectiva
                        : undefined,
                    tipoAplicacion:
                      this.tipoPlaga === "Maleza" &&
                        this.aplicacionSelectiva === "Si" &&
                        this.tipoAplicacion !== ""
                        ? this.tipoAplicacion
                        : undefined,
                    eiqTotal: this.calcEiqTotal(this.productos),
                    plagasPulverizacion: data,
                    productosPulverizacion: this.productos
                  });
                  this.$emit("submit", {
                    actividades
                  });
                }
              }
            ]
          });
          alert.present();
        } else {
          const actividades = [];
          actividades.push({
            _id: this.actividad._id,
            campaniaCultivo: this.campaniaCultivo,
            fecha: this.fecha,
            tipo: "Pulverización",
            tipoPlaga: this.tipoPlaga,
            momentoPulverizacion: this.momento,
            aplicacionSelectiva:
              this.tipoPlaga === "Maleza" && this.aplicacionSelectiva !== ""
                ? this.aplicacionSelectiva
                : undefined,
            tipoAplicacion:
              this.tipoPlaga === "Maleza" &&
                this.aplicacionSelectiva === "Si" &&
                this.tipoAplicacion !== ""
                ? this.tipoAplicacion
                : undefined,
            eiqTotal: this.calcEiqTotal(this.productos),
            plagasPulverizacion: data,
            productosPulverizacion: this.productos
          });
          this.$emit("submit", {
            actividades
          });
        }
      } else {
        const alert = await alertController.create({
          header: "Error al crear la actividad",
          message: error2,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
ion-tab-button {
  --ripple-color: var(--ion-color-primary);
  --color: var(--ion-color-dark);
  --color-selected: var(--ion-color-primary);
}

ion-tab-button.tab-selected {
  --background: var(--ion-color-primary) !important;
  --ripple-color: var(--ion-color-light);
  --color-selected: var(--ion-color-dark);
}

ion-tab-bar {
  justify-content: center;
}

.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

.smallFont {
  font-size: small;
}

ion-item {
  --pading-start: 8px;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

ion-content {
  --background: transparent;
}
</style>
