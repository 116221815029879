<template>
  <ion-content :scroll-events="true">
    <ion-card>
      <ion-card-header class="xLargeFont">
        {{ editing ? "Editar Producto aplicado" : "Crear Producto a aplicar" }}
      </ion-card-header>
      <ion-card-content>
        <v-form @submit="submitEditOrCreate">
          <!--             <ion-label position="floating">Producto</ion-label>
            <ion-select
              :value="producto"
              @ionChange="producto = $event.target.value"
              :placeholder="producto ? producto.nombre : undefined"
            >
              <ion-select-option
                v-for="p in allProducts"
                :value="p"
                :key="p._id"
              >
                {{ p.nombre }}
              </ion-select-option>
            </ion-select> -->
          <ion-label style="margin-left: 8px">
            Producto
          </ion-label>
          <ion-searchbar
            placeholder="Nombre del Producto"
            @ionChange="filtroProducto = $event.target.value"
          />
          <ion-item v-if="filtroProducto === ''">
            <ion-label style="font-weight: 600">
              {{ producto ? producto.nombre : "" }}
            </ion-label>
          </ion-item>
          <ion-list lines="full" v-for="prod in allProducts" :key="prod._id">
            <ion-item
              class="itemBorder"
              button="true"
              type="button"
              @click="selectProducto(prod)"
            >
              <ion-label
                style="white-space: normal; font-size: 12px !important"
              >
                {{ prod.nombre }}
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-row :class="filtroProducto ? 'ion-margin-top' : ''">
            <ion-col>
              <ion-item>
                <ion-label position="floating">Dosis</ion-label>
                <ion-input
                  v-model="dosis"
                  autocapitalize="off"
                  type="text"
                  inputmode="text"
                  autocomplete="off"
                  placeholder="Kg/Ha"
                ></ion-input>
              </ion-item>
            </ion-col>
            <ion-col size="auto" style="margin-top:41px">
              <ion-label style="font-size:85%">Kg/Ha</ion-label>
            </ion-col>
          </ion-row>

          <ion-item>
            <ion-label position="floating">Momento</ion-label>
            <ion-select
              :value="momento"
              @ionChange="momento = $event.target.value"
            >
              <ion-select-option v-for="m in momentos" :value="m" :key="m">
                {{ m }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-button
            fill="clear"
            size="large"
            color="danger"
            @click="$emit('delete')"
            v-if="editing"
          >
            <ion-icon :md="icons.trash" :ios="icons.trash"></ion-icon>
          </ion-button>
          <ion-row class="ion-float-right">
            <ion-col>
              <ion-button
                fill="outline"
                class="ion-margin-top"
                color="medium"
                @click="cancel"
              >
                Cancelar
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                class="ion-float-right ion-margin-top ion-margin-bottom"
                @click="submitEditOrCreate"
              >
                Aceptar
              </ion-button>
            </ion-col>
          </ion-row>
        </v-form>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import * as V from "vee-validate/dist/vee-validate";
import {
  IonInput,
  IonLabel,
  alertController,
  IonSearchbar,
  IonList,
  IonCard,
  IonCardHeader,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonItem,
  IonRow,
  IonCol,
  //IonGrid,
  IonButton
} from "@ionic/vue";
import { mapState } from "vuex";
import { trashOutline } from "ionicons/icons";
import { Productos } from "@/api";

export default {
  components: {
    IonInput,
    IonCard,
    IonCardHeader,
    IonLabel,
    IonItem,
    IonSearchbar,
    IonList,
    //IonGrid,
    IonSelect,
    IonSelectOption,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    VForm: V.Form
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    productoFertilizacion: { type: Object, default: () => ({}) }
  },

  data: () => ({
    producto: null,
    dosis: "",
    allProducts: [],
    momentos: [
      "Presiembra",
      "Siembra",
      "Vegetativo temprano",
      "Vegetativo tardío",
      "Reproductivo temprano",
      "Reproductivo tardío"
    ],
    momento: "",
    filtroProducto: "",
    delayTimer: null,
    icons: {
      trash: trashOutline
    }
  }),

  computed: {
    ...mapState(["user"]),
    editing() {
      return (
        this.productoFertilizacion._id ||
        (this.productoFertilizacion && !this.productoFertilizacion._id)
      );
    }
  },

  watch: {
    success(val) {
      if (val) {
        this.cleaner();
      }
    },
    lote() {
      this.cleaner();
      if (this.editing) {
        this.fillFields();
      }
    },
    async filtroProducto(val) {
      if (val !== "") {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
          this.allProducts = await this.getAllProductos();
          const p = this.allProducts.find(prod => prod.nombre === "Otro");
          if (p && p._id) {
            this.allProducts = this.allProducts.filter(
              prod => prod.nombre !== "Otro"
            );
            this.allProducts.push(p);
          }
        }, 500);
      } else {
        this.allProducts = [];
      }
    }
  },

  created() {
    if (this.editing) {
      this.fillFields();
    }
  },

  async mounted() {
    if (this.editing) {
      this.fillFields();
    }
  },

  methods: {
    cleaner() {
      this.producto = null;
      this.dosis = "";
      this.momento = "";
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    selectProducto(prod) {
      this.producto = prod;
      this.filtroProducto = "";
    },

    async getAllProductos() {
      const query = {
        deleted: false,
        nombreLower: {
          $regex: this.filtroProducto.toLowerCase(),
          $options: "i"
        }
      };
      const { data, error } = await Productos.get({
        query: query,
        sort: { nombreLower: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo los Productos",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      } else {
        return data;
      }
    },

    async submitEditOrCreate() {
      this.$emit("update:success", false);
      let error = "";
      if (this.producto === null) {
        error += "<li>Elija un <b>Producto</b>." + `<br/></li>`;
      }

      if (!this.dosis) {
        error += "<li>Complete el campo <b>Dosis</b>." + `<br/></li>`;
      } else if (isNaN(this.dosis)) {
        error += "<li>El campo <b>Dosis</b> es numérico." + `<br/></li>`;
      } else if (this.dosis < 0) {
        error +=
          "<li>El campo <b>Dosis</b> debe ser un número positivo." +
          `<br/></li>`;
      }

      if (!this.momento) {
        error += "<li>Elija un <b>Momento</b>." + `<br/></li>`;
      }
      if (!error) {
        this.$emit("submit", {
          productoFertilizacion: {
            _id: this.productoFertilizacion._id,
            producto: this.producto,
            dosis: this.dosis,
            momento: this.momento
          }
        });
      } else {
        const alert = await alertController.create({
          header: "Error al agregar el producto",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    fillFields() {
      this.producto = this.productoFertilizacion.producto;
      this.dosis = this.productoFertilizacion.dosis;
      this.momento = this.productoFertilizacion.momento;
    }
  }
};
</script>

<style scoped>
.lower-content {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.little-up-content {
  margin-bottom: 10px;
}

.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

ion-select {
  --placeholder-opacity: 1 !important;
  --placeholder-color: var(--ion-color-dark);
}

ion-item {
  --pading-start: 8px;
}

ion-content {
  --background: transparent;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}
</style>
